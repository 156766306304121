import { useGetConnectionsAsync } from 'src/services/Api/common'
import { useMemo } from 'react'
import { MRT_Cell, MRT_RowData } from 'material-react-table'
import AsyncSelect from './AsyncSelect'
import { getCellIsEditable, getFormattedTransporterData } from './utils'

interface EditableTransporterProps<TData extends MRT_RowData> {
  cell: MRT_Cell<TData>
  row: TData
  service: 'pickup' | 'delivery'
}

const EditableTransporter = <TData extends MRT_RowData>({
  cell,
  row,
  service,
}: EditableTransporterProps<TData>) => {
  const isEditable = useMemo(() => getCellIsEditable(cell), [cell])

  return (
    <AsyncSelect
      value={row.original[`${service}_transporter_id`]}
      label={row.original[`${service}_transporter_name`]}
      isEditable={isEditable}
      containerId={row.original.id}
      additionalParams={{ service }}
      formatter={getFormattedTransporterData}
      fetchFunction={useGetConnectionsAsync}
      fetchParams={{ role: 'transporter', per_page: '100' }}
    />
  )
}

export default EditableTransporter
