import { Link, Typography } from '@mui/material'
import { Trans } from 'react-i18next'

type DocTypeNames =
  | 'Forwarding Conditions'
  | 'Terms and conditions'
  | 'Visibility terms and conditions'
  | 'Warehousing Condition'

const noDocTypeText = {
  'Forwarding Conditions': 'Dutch Forwarding Conditions by FENEX',
  'Visibility terms and conditions': 'General Terms and Conditions',
  'Terms and conditions': 'General Terms & Conditions',
  'Warehousing Condition': '',
}

interface PublicDocumentProps {
  seller: IOrganizationWithTerms
  type: DocTypeNames
}

const ShipmentBookingTermsAndConditionsPublicDocument = ({
  type,
  seller,
}: PublicDocumentProps) => {
  const doc = seller?.public_documents?.find(
    (item) => item.document_type.name === type
  )

  if (!doc) {
    return <>{noDocTypeText[type]}</>
  }

  if (type === 'Warehousing Condition') {
    return (
      <Trans
        i18nKey={'shipment_booking.terms_and_conditions.warehousing'}
        defaults=" The <warehousingConditionLink />, latest version, are applicable to all orders which exclusively concern the warehousing of property."
        components={{
          warehousingConditionLink: (
            <Link href={doc.file_url} rel="noopener noreferrer" target="_blank">
              <Typography
                display="inline"
                variant="body1"
                className="hyperlink bold"
              >
                Dutch Warehousing Conditions
              </Typography>
            </Link>
          ),
        }}
      />
    )
  }

  return (
    <Link href={doc.file_url} rel="noopener noreferrer" target="_blank">
      <Typography display="inline" variant="body1" className="hyperlink bold">
        {doc.name}
      </Typography>
    </Link>
  )
}

export default ShipmentBookingTermsAndConditionsPublicDocument
