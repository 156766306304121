import { SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TextareaAutosizeProps } from '@mui/base/TextareaAutosize'
import { StyledTextArea } from './styled'

interface TextAreaProps extends TextareaAutosizeProps {
  value: string
  error?: boolean
  maxLength?: number
  showCharacterCount?: boolean
  sx?: SxProps
}

const TextArea = ({
  value,
  maxLength = 512,
  error = undefined,
  showCharacterCount = false,
  ...otherProps
}: TextAreaProps) => {
  const { t } = useTranslation()
  const errorClassName = error ? 'error' : ''
  return (
    <>
      <StyledTextArea
        className={errorClassName}
        value={value}
        {...otherProps}
      />
      {showCharacterCount && (
        <Typography
          variant="body2"
          color={value.length < maxLength + 1 ? 'primary' : 'error'}
          className={errorClassName}
          data-testid="character-count"
        >
          {t('common.forms.fields.textarea.hints.character_count', {
            maxLength,
            count: value.length,
            defaultValue: '{{count}}/{{maxLength}} characters',
          })}
        </Typography>
      )}
    </>
  )
}

export default TextArea
