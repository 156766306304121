import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/organizations'

export const initialOrganizationsState: IOrganizationsState = {
  list: [],
  preloaded: [],
  preloadedFetching: false,
  exportFile: null,
  organization: {
    id: 0,
    name: '',
    prefix: '',
    logo: '',
    role_code: 'customer',
    default_address: null,
    preferred_forwarder_id: null,
    preferred_shipment_role_id: null,
  },
}

const receiveSingleOrganization: Reducer<IOrganizationsState, AnyAction> = (
  state,
  action: AnyAction
) => ({
  ...state,
  organization: action.payload,
})

const requestPreloaded: Reducer<IOrganizationsState, AnyAction> = (state) => ({
  ...state,
  preloadedFetching: true,
})

const receivePreloaded: Reducer<IOrganizationsState, AnyAction> = (
  state,
  action: AnyAction
) => ({
  ...state,
  preloaded: action.payload,
  preloadedFetching: false,
})

const receiveOrganizationsList: Reducer<IOrganizationsState, AnyAction> = (
  state,
  action: AnyAction
) => ({
  ...state,
  list: action.payload,
})

const clearOrganizationsList: Reducer<IOrganizationsState, AnyAction> = (
  state
) => ({
  ...state,
  list: [],
})

const clearPreloaded: Reducer<IOrganizationsState, AnyAction> = (state) => ({
  ...state,
  preloaded: [],
})

const getExportedData: Reducer<IOrganizationsState, AnyAction> = (
  state,
  action
) => {
  return {
    ...state,
  }
}

const deleteExportedFileData: Reducer<IOrganizationsState, AnyAction> = (
  state,
  action
) => {
  return {
    ...state,
    exportFile: null,
  }
}

export default createReducer(initialOrganizationsState, {
  [actions.ORGANIZATIONS_PRELOAD]: requestPreloaded,
  [actions.ORGANIZATIONS_PRELOAD_SUCCESS]: receivePreloaded,
  [actions.ORGANIZATIONS_GET_DATA_SUCCESS]: receiveOrganizationsList,
  [actions.ORGANIZATION_GET_DATA_SUCCESS]: receiveSingleOrganization,
  [actions.ORGANIZATIONS_CLEAR_LIST]: clearOrganizationsList,
  [actions.ORGANIZATIONS_CLEAR_PRELOADED]: clearPreloaded,
  [actions.ORGANIZATION_GET_EXPORTED_RATES_DATA_SUCCESS]: getExportedData,
  [actions.ORGANIZATION_DELETE_EXPORTED_RATES_DATA]: deleteExportedFileData,
})
