import { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import LaunchIcon from '@mui/icons-material/Launch'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { promisifyAction, permissionTo } from 'src/utils'
import {
  organizationGetExportedRatesData,
  organizationDeleteExportedRatesData,
  getListShypplePublickDocumentData,
} from 'src/stores/actionCreators'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { downloadFile } from 'src/utils/helpers/content'
import RatesTabs from '../../components/Rates/RatesTabs'
import RatesFilters from '../../components/Rates/RatesFilters'
import { localChargesName } from '../../components/Rates/constants'
import './styles.scss'

export type IRatesFiltersType =
  | Partial<{
      search: string
      origin_port_ids: number[]
      destination_port_ids: number[]
      origin_country_ids: number[]
      destination_country_ids: number[]
      tradelane_ids: number[]
      valid_from?: string
      valid_to?: string
    }>
  | undefined

const Rates: FunctionComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const [downloading, setDownloading] = useState<boolean>(false)
  const [
    localChargesDocument,
    setLocalChargesDocument,
  ] = useState<IPublicDocuments | null>(null)
  const [filters, setFilters] = useState<IRatesFiltersType>(undefined)

  const { exportFile } = useSelector((globalState: IGlobalState) => ({
    exportFile: globalState.organizations.exportFile,
  }))

  const downloadListPublicDocumentsAsync = promisifyAction(
    dispatch,
    getListShypplePublickDocumentData
  )

  useEffect(() => {
    const fetchDataAsync = async () => {
      const response = await downloadListPublicDocumentsAsync()
      response.data.find((item: IPublicDocuments) =>
        item.document_type.name.includes(localChargesName)
          ? setLocalChargesDocument(item)
          : null
      )
    }
    fetchDataAsync()
  }, [])

  useEffect(() => {
    if (exportFile) {
      const url = window.URL.createObjectURL(
        new Blob([exportFile], { type: 'application/xml' })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Ratessheet-${DateTime.now().toFormat('dd-MMM-yyyy')}.xlsx`
      )
      document.body.appendChild(link)
      link.click()
      dispatch(organizationDeleteExportedRatesData())
    }
  }, [exportFile])

  const downloadRatesAsync = promisifyAction(
    dispatch,
    organizationGetExportedRatesData
  )

  const handleClickOnDownloadButton = () => {
    setDownloading(true)
    downloadRatesAsync().finally(() => {
      setDownloading(false)
      dispatch(
        showNotification({
          message: t(
            'rates.notifications.export_ratesheet_message',
            'We are generating an Excel file in the background. Once finished the file is emailed to you.'
          ),
          severity: 'success',
        })
      )
    })
  }

  const handleClickOnDemurrageAndDetentionButton = () => {
    history.push('/demurrage-detention')
  }

  const handleClickOnDownloadLocalChargesButton = () => {
    localChargesDocument &&
      downloadFile(localChargesDocument?.file_url, `${localChargesName}.pdf`)
  }

  return (
    <div className="rates">
      <Grid container spacing={2} mb={2}>
        <Grid item md={6} xs={12}>
          <Typography
            variant="h2"
            children={t('rates.title', 'Rates')}
            height="100%"
            display="flex"
            alignItems="center"
          />
        </Grid>
        <Grid item xs={12} md={6} display="flex" justifyContent="end">
          {permissionTo('demurrage_and_detention_prices.view') && (
            <Button
              variant="outlined"
              size="large"
              sx={{ mr: 2 }}
              startIcon={<ReceiptLongOutlinedIcon />}
              onClick={handleClickOnDemurrageAndDetentionButton}
              data-testid="demurrage-and-detention-button"
            >
              {t('demurrage_and_detention.title', 'Demurrage and detention')}
            </Button>
          )}
          {localChargesDocument && (
            <LoadingButton
              size="large"
              sx={{ mr: 2 }}
              startIcon={<LaunchIcon />}
              onClick={handleClickOnDownloadLocalChargesButton}
              variant="outlined"
            >
              {t('rates.table_content.headings.local_charges', 'Local charges')}
            </LoadingButton>
          )}
          <LoadingButton
            size="large"
            loading={downloading}
            startIcon={<SaveAltIcon />}
            onClick={handleClickOnDownloadButton}
            variant={downloading ? 'contained' : 'outlined'}
            data-testid={
              downloading
                ? 'loading-rate-sheet-file-button'
                : 'download-rate-sheet-file-button'
            }
          >
            {t(
              'rates.table_content.headings.rates_sheet',
              'Download rate sheet'
            )}
          </LoadingButton>
        </Grid>
      </Grid>
      <RatesFilters setFilters={setFilters} />
      <Box mb={2} />
      <RatesTabs filters={filters} />
    </div>
  )
}

export default Rates
