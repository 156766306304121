import { Menu, Stack, MenuItem, Typography, IconButton } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useCallback, useState } from 'react'
import { promisifyAction } from 'src/utils'
import { copyContainerAttributes } from 'src/stores/actionCreators'
import { useDispatch } from 'react-redux'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { useTranslation } from 'react-i18next'

interface ContainerOverviewHeaderCollapsedActionsProps {
  collapsed?: boolean
  isSingleContainer: boolean
  shipmentData: IShipmentMainData
  containerData: IShipmentContainer
  confirmDeletion: (id: number) => void
  openContainerWindow: (id: number) => void
  openDuplicateContainerWindow: (id: number) => void
}

const ContainerOverviewHeaderCollapsedActions = ({
  collapsed,
  shipmentData,
  containerData,
  confirmDeletion,
  isSingleContainer,
  openContainerWindow,
  openDuplicateContainerWindow,
}: ContainerOverviewHeaderCollapsedActionsProps) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  const copyContainerAttributesAsync = promisifyAction(
    dispatch,
    copyContainerAttributes
  )

  const onContainerModalOpen = useCallback((): void => {
    openContainerWindow(containerData.id)
  }, [openContainerWindow, containerData.id])

  const onDuplicateContainerModalOpen = useCallback((): void => {
    openDuplicateContainerWindow(containerData.id)
  }, [openDuplicateContainerWindow, containerData.id])

  const showSuccessNotification = () => {
    dispatch(
      showNotification({
        message: t(
          'shipment_containers.notifications.container_data_copied',
          'Container data copied successfully.'
        ),
        severity: 'success',
      })
    )
  }

  const copyToAllContainers = () => {
    copyContainerAttributesAsync({
      shipment_id: shipmentData.id,
      container_id: containerData.id,
    }).then(showSuccessNotification)
  }

  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleActionsClose = () => {
    setAnchorEl(null)
  }

  const handleEditClick = () => {
    onContainerModalOpen()
    handleActionsClose()
  }

  const handleRemoveClick = () => {
    confirmDeletion(containerData.id)
    handleActionsClose()
  }

  const handleDuplicateClick = () => {
    onDuplicateContainerModalOpen()
    handleActionsClose()
  }

  const handleCopyToAllContainersClick = () => {
    handleActionsClose()
    copyToAllContainers()
  }

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between">
      <IconButton
        color="primary"
        size="medium"
        id="actions-toggler"
        data-testid="actions-toggler"
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleActionsClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleActionsClose}
        MenuListProps={{
          'aria-labelledby': 'actions-toggler',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleEditClick}>
          <Typography
            variant="body1"
            children={t('common.buttons.edit_container', 'Edit container')}
          />
        </MenuItem>
        <MenuItem onClick={handleRemoveClick}>
          <Typography
            variant="body1"
            children={t('common.buttons.remove_container', 'Remove container')}
          />
        </MenuItem>
        <MenuItem onClick={handleDuplicateClick}>
          <Typography
            variant="body1"
            children={t(
              'common.buttons.duplicate_container',
              'Duplicate container'
            )}
          />
        </MenuItem>
        {!isSingleContainer && (
          <MenuItem onClick={handleCopyToAllContainersClick}>
            <Typography
              variant="body1"
              children={t(
                'common.buttons.copy_to_all_containers',
                'Copy to all containers'
              )}
            />
          </MenuItem>
        )}
      </Menu>
      <IconButton size="small">
        {collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </IconButton>
    </Stack>
  )
}

export default ContainerOverviewHeaderCollapsedActions
