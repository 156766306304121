import { FunctionComponent, useMemo } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { permissionTo } from 'src/utils'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import './styles.scss'

interface IProps {
  collapsed: boolean
  shipmentData: IShipmentMainData
  isLclOrAir: boolean
  isSingleContainer?: boolean
  onNewContainerClick: () => void
}

const ShipmentOverviewHeader: FunctionComponent<IProps> = (props) => {
  const {
    shipment_phase,
    closing_datetime,
    delivery_available_from,
  } = props.shipmentData

  const { t } = useTranslation()

  const isOriginPhase: boolean = shipment_phase === 'origin'

  const closingDateTimeText = useMemo(() => {
    if (!closing_datetime) {
      return t('shipment_containers.to_be_announced', 'To be announced')
    }
    return t('common.date_medium', {
      date: closing_datetime,
      defaultValue: '{{date, DATE_MED}}',
    })
  }, [closing_datetime])

  const deliveryAvailableFromText = useMemo(() => {
    if (!delivery_available_from) {
      return t('shipment_containers.to_be_announced', 'To be announced')
    }
    return t('common.date_medium', {
      date: delivery_available_from,
      defaultValue: '{{date, DATE_MED}}',
    })
  }, [delivery_available_from])

  return (
    <Box
      pt={2}
      pb={1}
      mb={2}
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box flex-grow={1}>
        <Typography
          variant="body1Strong"
          children={
            isOriginPhase
              ? t(
                  'shipment_containers.cargo_closing_date',
                  'Cargo closing date'
                )
              : t(
                  'shipment_containers.delivery_available_from',
                  'Delivery available from'
                )
          }
        />
        <Typography variant="body1" mt={0.5}>
          {isOriginPhase ? closingDateTimeText : deliveryAvailableFromText}
        </Typography>
      </Box>
      {permissionTo('shipments.containers.manage') && (
        <Box display="flex" justifyContent="end" alignItems="center">
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={props.onNewContainerClick}
            startIcon={<AddIcon />}
            data-testid="add-shipment-container-trigger"
          >
            {t('common.buttons.add_container', 'Add container')}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default ShipmentOverviewHeader
