import ListItem from '@mui/material/ListItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'

const ShowHideDialogListItem = (props: {
  onClick: () => void
  selected: boolean
  label: string
  visible: boolean
  indeterminate?: boolean
  hideCheckbox?: boolean
}) => {
  const {
    onClick,
    selected,
    label,
    visible,
    indeterminate,
    hideCheckbox,
  } = props
  return (
    <ListItem disablePadding onClick={onClick}>
      <ListItemButton selected={selected}>
        {!hideCheckbox && (
          <Checkbox
            sx={{ p: 0, pr: 1 }}
            checked={visible}
            indeterminate={indeterminate}
            inputProps={{ 'aria-label': 'visibility-checkbox' }}
          />
        )}

        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  )
}

export default ShowHideDialogListItem
