import { Tooltip, Chip } from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { useTranslation } from 'react-i18next'

interface IProps {
  podCustomsDocumentsPresent: boolean
  portbaseEnabled: boolean
}

const getInlandTransportChipTooltip = (
  podCustomsDocumentsPresent: boolean,
  portbaseEnabled: boolean,
  t
): string => {
  if (!portbaseEnabled) {
    return t(
      'transports.table.customs_documents.no_information',
      'No information available.'
    )
  }
  if (podCustomsDocumentsPresent) {
    return t(
      'transports.table.customs_documents.present',
      'Customs documents present.'
    )
  }
  return t(
    'transports.table.customs_documents.missing',
    'Customs documents missing.'
  )
}

const InlandTransportRowDocumentsChip: React.FC<IProps> = ({
  podCustomsDocumentsPresent,
  portbaseEnabled,
}) => {
  const { t } = useTranslation()

  const title = getInlandTransportChipTooltip(
    podCustomsDocumentsPresent,
    portbaseEnabled,
    t
  )

  const chipColor =
    podCustomsDocumentsPresent && portbaseEnabled ? 'success' : 'default'

  const icon = portbaseEnabled ? (
    <DescriptionIcon
      color={!podCustomsDocumentsPresent ? 'action' : 'inherit'}
      sx={{ marginTop: '5px' }}
    />
  ) : (
    <RemoveCircleIcon
      color={!podCustomsDocumentsPresent ? 'action' : 'inherit'}
      sx={{ marginTop: '5px' }}
    />
  )

  return (
    <>
      <Tooltip color="primary" title={title} placement="bottom-start">
        <Chip
          variant="filled"
          label={icon}
          color={chipColor}
          sx={{ marginLeft: 1 }}
        />
      </Tooltip>
    </>
  )
}
export default InlandTransportRowDocumentsChip
