import { useMutation, useQueries } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { useState, createContext } from 'react'
import { useHistory } from 'react-router'
import { AxiosError } from 'axios'
import { apiClient } from '../../services/http-common'
import { createQueryString } from '../../services/utils'
import { showNotification } from '../../stores/actionCreators/notifications'
import { IShippingInstruction } from './types'
import ShippingInstructionsView from './components/ShippingInstructionsView'

import './index.scss'

export const FormContext = createContext<any>(null)

const ShippingInstructions = ({
  match: {
    params: { shipment_id, booking_id },
  },
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [state, setState] = useState<IShippingInstruction | null>(null)
  const [booking, setBooking] = useState<IBooking | null>(null)

  const shippingInstructionsMutation = useMutation({
    mutationFn: () => {
      dispatch(
        showNotification({
          message: 'Creating a shipping instruction...',
          severity: 'info',
        })
      )
      return apiClient.post<any, any>(
        `shipments/${shipment_id}/shipping_instructions`,
        JSON.stringify(state)
      )
    },
    onError: (error: AxiosError) => {
      dispatch(
        showNotification({
          message: error?.response?.data?.message || 'No message',
          severity: 'error',
        })
      )
    },
    onSuccess: () => {
      dispatch(
        showNotification({
          message: 'Shipping instruction is created',
          severity: 'success',
        })
      )
      history.push(`/shipments/${shipment_id}/documentation`)
    },
  })

  const [bookings, instruction] = useQueries({
    queries: [
      {
        queryKey: ['bookings', shipment_id],
        queryFn: () =>
          apiClient.get<any, any>(`shipments/${shipment_id}/bookings`),
        onSuccess: ({ bookings }: { bookings: IBooking[] }) => {
          const booking = bookings.find(
            (booking: IBooking) => booking.id === +booking_id
          )
          if (!booking) return history.push('/404')
          setBooking(booking)
        },
      },
      {
        queryKey: ['instruction', booking_id],
        queryFn: () =>
          apiClient.get<any, any>(
            `shipments/${shipment_id}/shipping_instructions/new` +
              createQueryString({ booking_id })
          ),
        onSuccess: (data: IShippingInstruction) => {
          setState(data)
        },
      },
    ],
  })

  if (instruction.isLoading && bookings.isLoading) return null

  return (
    <FormContext.Provider value={{ state, setState }}>
      {state && (
        <ShippingInstructionsView
          shipmentId={shipment_id}
          booking={booking}
          isSubmitting={shippingInstructionsMutation.isLoading}
          state={state}
          onSubmit={shippingInstructionsMutation.mutateAsync}
        />
      )}
    </FormContext.Provider>
  )
}

export default ShippingInstructions
