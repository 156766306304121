import MenuItem from '@mui/material/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'
import groupBy from 'lodash/groupBy'
import { CircularProgress, Typography } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useGetShipmentStatuses } from 'src/services/Api/common'
import { useUpdateShipment } from 'src/services/Api/overview'
import { customFields, tableCellMutationKey } from '../../constants'
import { SelectInputBase } from './styled'

const EditableShipmentStatusSelect = ({ value, shipmentId }) => {
  const { data: shipmentStatuses = [] } = useGetShipmentStatuses({
    refetchOnMount: false,
  })

  const groupedShipmentStatuses = groupBy(
    shipmentStatuses,
    (item) => item?.phase?.name ?? ''
  )

  const {
    mutateAsync: updateInlandTransport,
    isLoading: isUpdating,
  } = useUpdateShipment({
    retry: 0,
  })

  const updateCell = (event: SelectChangeEvent) => {
    const selectValue = event.target.value
    if (selectValue === value) {
      return
    }
    updateInlandTransport({
      mutationKey: [tableCellMutationKey],
      params: {
        custom_fields: customFields,
        shipments: [
          {
            id: shipmentId,
            status_id: selectValue,
          },
        ],
      },
    })
  }

  if (isUpdating) {
    return <CircularProgress size={18} />
  }

  return (
    <Select
      labelId="editable-shipment-status-select-label"
      id="editable-shipment-status-select"
      data-testid="editable-shipment-status-select"
      value={value}
      onChange={updateCell}
      fullWidth
      input={
        <SelectInputBase
          inputProps={{
            'data-testid': 'editable-shipment-status-select-input',
          }}
        />
      }
    >
      {Object.keys(groupedShipmentStatuses).map((key) => {
        return [
          <ListSubheader key={key}>
            <Typography variant="body1Strong">{key}</Typography>
          </ListSubheader>,
          groupedShipmentStatuses[key]?.map((option) => {
            return (
              <MenuItem key={option.id} value={option?.id ?? ''}>
                {option.name}
              </MenuItem>
            )
          }),
        ]
      })}
    </Select>
  )
}

export default EditableShipmentStatusSelect
