import { InputLabel, MenuItem, Select } from '@mui/material'
import { useContext } from 'react'
import { FormContext } from '..'
import { setProperty } from '../helpers'

const ControlledSelect = ({ name, label, options, disabled = false }) => {
  const { state, setState } = useContext(FormContext)

  const handleChange = (event) => {
    setState(setProperty(state, name, event.target.value))
  }

  return (
    <>
      <InputLabel disabled={disabled} htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        fullWidth
        value={state[name] || ''}
        id={name}
        onChange={handleChange}
        variant="outlined"
      >
        {options.map(({ id, title }) => (
          <MenuItem key={id} value={id}>
            {title}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

export default ControlledSelect
