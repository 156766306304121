const boxShadowMap = {
  left: 'rgba(0, 0, 0, 0.2) -4px 0px 8px -6px inset',
  right: 'rgba(0, 0, 0, 0.2) 4px 0px 8px -6px inset',
}

const getMuiTableCellProps = ({ column }) => {
  const pin = `${column.getIsPinned()}`
  const boxShadow = boxShadowMap?.[pin] ?? 'none'
  return {
    sx: {
      boxShadow,
      backgroundColor: 'common.white',
      '& .Mui-TableHeadCell': {
        '&-Content': {
          height: '26px',
        },
        '&-Content-Actions, &-ResizeHandle-Wrapper': {
          visibility: 'hidden',
          display: 'none',
          '&:active > hr': {
            backgroundColor: 'primary.main',
          },
        },
      },
      '&:hover': {
        '& .Mui-TableHeadCell': {
          '&-Content-Actions, &-ResizeHandle-Wrapper': {
            visibility: 'visible',
            display: 'block',
          },
        },
      },
    },
  }
}

const getMRTOptions = ({ tableConfig }) => {
  const {
    muiColumnActionsButtonProps,
    muiTableBodyCellProps,
    muiTableHeadCellProps,
    muiLinearProgressProps,
    ...otherProps
  } = tableConfig

  return {
    muiColumnActionsButtonProps: {
      size: 'small',
      ...muiColumnActionsButtonProps,
    },
    muiTableBodyCellProps: ({ column }) => ({
      className: 'data-table-cell',
      tabIndex: 0,
      ...getMuiTableCellProps({ column }),
      ...muiTableBodyCellProps,
    }),
    muiTableHeadCellProps: ({ column }) => ({
      className: 'data-table-cell',
      ...getMuiTableCellProps({ column }),
      ...muiTableHeadCellProps,
    }),
    muiLinearProgressProps: {
      sx: {
        display: 'none',
      },
      ...muiLinearProgressProps,
    },
    ...otherProps,
  }
}

export default getMRTOptions
