import { Trans } from 'react-i18next'
import PublicDocument from './PublicDocument'

const ShipmentBookingTermsAndConditions = ({ forwarderName, seller }) => {
  return (
    <Trans
      i18nKey={'shipment_booking.terms_and_conditions'}
      defaults="I hereby acknowledge and accept that {{forwarderName}} is acting as a digital freight forwarder and that for all freight forwarding services provided by {{forwarderName}}, <forwardingConditions />, latest version, and the <termsAndConditions /> shall exclusively apply.<warehousingConditions /> All subcontractors and carriers appointed by Shypple B.V. have limited liability. Shypple B.V. recommends that goods are insured."
      values={{ forwarderName }}
      components={{
        forwardingConditions: (
          <PublicDocument type="Forwarding Conditions" seller={seller} />
        ),
        termsAndConditions: (
          <PublicDocument type="Terms and conditions" seller={seller} />
        ),
        warehousingConditions: (
          <PublicDocument type="Warehousing Condition" seller={seller} />
        ),
      }}
    />
  )
}

export default ShipmentBookingTermsAndConditions
