import { FunctionComponent, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Button, Menu, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import ButtonGroup from '@mui/material/ButtonGroup'
import EmailsFormContainer from './SendViaEmail'

const useStyles = makeStyles((theme) => ({
  arrowIcon: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.complex,
    }),
    transform: ({ open }: { open: boolean }) =>
      open ? 'rotate(180deg)' : 'rotate(0)',
  },
}))

interface IProps {
  openWindow: (type: string) => void
}

type Scope = 'pickup' | 'delivery' | 'overview'

const emailFormTitles = (t): Record<Scope, string> => ({
  pickup: t(
    'transports.containers_sharing.pickup.dalog.title',
    'Send pickup email'
  ),
  delivery: t(
    'transports.containers_sharing.delivery.dalog.title',
    'Send delivery email'
  ),
  overview: t(
    'transports.containers_sharing.overview.dalog.title',
    'Send container overvie email'
  ),
})

const PickupsAndDeliveriesTableActions: FunctionComponent<IProps> = ({
  openWindow,
}) => {
  const { t } = useTranslation()
  const [emailFormScope, setEmailsFormScope] = useState<Scope | null>(null)
  const { selectedRowsIds, scope } = useSelector((state: IGlobalState) => ({
    selectedRowsIds: state.pickUpsAndDeliveries.selectedInlandTransportRowsIds,
    scope: state.pickUpsAndDeliveries.filters.scope,
  }))

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const classes = useStyles({
    open,
  })

  return (
    <Box
      m={0.5}
      sx={{
        position: 'absolute',
        backgroundColor: 'grey.50',
        zIndex: '1',
        borderRadius: 1,
        height: '40px',
      }}
    >
      <ButtonGroup sx={{ height: '100%' }}>
        <Button disabled>
          <Typography
            variant="body1"
            children={t(
              'transports.table_actions.items_selected',
              '{{total}} selected',
              { total: selectedRowsIds.length }
            )}
            data-testid="selected-rows-counter"
          />
        </Button>
        <Button
          onClick={() => openWindow(scope)}
          data-testid="open-window-button"
        >
          <LocalShippingOutlinedIcon color="primary" sx={{ mr: 1 }} />
          <Typography
            variant="body1"
            children={
              scope === 'pickup'
                ? t('transports.table_actions.plan_pickup', 'Plan pickup')
                : t('transports.table_actions.plan_delivery', 'Plan delivery')
            }
          />
        </Button>
        <Button onClick={handleClick} data-testid="send-via-email-button">
          <MailOutlineIcon color="primary" sx={{ mr: 1 }} />
          <Typography>
            {t('common.buttons.send_email', 'Send email')}
          </Typography>
          <ExpandMoreIcon
            sx={{ mr: -1, ml: 1 }}
            className={classes.arrowIcon}
          />
        </Button>
      </ButtonGroup>
      {emailFormScope && (
        <EmailsFormContainer
          selectedRowsIds={selectedRowsIds}
          onClose={() => setEmailsFormScope(null)}
          scope={emailFormScope}
          title={emailFormTitles(t)[emailFormScope]}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        data-testid="send-via-email-menu"
      >
        {scope === 'pickup' && (
          <MenuItem
            data-testid="send-via-email-pickup"
            onClick={() => {
              handleClose()
              setEmailsFormScope(scope)
            }}
          >
            {t(
              'transports.containers_sharing.pickup.dalog.title',
              'Send pickup email'
            )}
          </MenuItem>
        )}
        {scope === 'delivery' && (
          <MenuItem
            data-testid="send-via-email-delivery"
            onClick={() => {
              handleClose()
              setEmailsFormScope(scope)
            }}
          >
            {t(
              'transports.containers_sharing.delivery.dalog.title',
              'Send delivery email'
            )}
          </MenuItem>
        )}
        <MenuItem
          data-testid="send-via-email-overview"
          onClick={() => {
            handleClose()
            setEmailsFormScope('overview')
          }}
        >
          {t(
            'transports.containers_sharing.overview.dalog.title',
            'Send container overview'
          )}
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default PickupsAndDeliveriesTableActions
