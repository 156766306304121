import { LoadingButton } from '@mui/lab'
import { Box, Grid, Paper, Typography, Link as MuiLink } from '@mui/material'
import { Link } from 'react-router-dom'
import { formatDate } from 'src/utils/helpers/shipmentDate'
import { formatText, shippingInstructionTitle } from '../helpers'
import { IShippingInstruction } from '../types'
import ControlledSelect from './ControlledSelect'
import ControlledTextArea from './ControlledTextArea'
import Party from './Party'

const ItemWrapper = ({ children }) => (
  <Box
    sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {children}
  </Box>
)

const movementTypeOptions = [
  { id: 'door_to_door', title: 'Door to door' },
  { id: 'door_to_port', title: 'Door to port' },
  { id: 'port_to_door', title: 'Port to door' },
  { id: 'port_to_port', title: 'Port to port' },
]

const freightPayableOptions = [
  { id: 'prepaid', title: 'Prepaid' },
  { id: 'collected', title: 'Collected' },
]

interface IShippingInstructionProps {
  state: IShippingInstruction
  booking: IBooking | null
  isSubmitting: boolean
  onSubmit: () => void
  shipmentId: string
}

const ShippingInstructions = ({
  state,
  isSubmitting,
  onSubmit,
  booking,
  shipmentId,
}: IShippingInstructionProps) => {
  const isInstructionCreated = state.id !== null

  if (!booking) return null

  const instructionTitle = shippingInstructionTitle(booking)

  const title = isInstructionCreated
    ? `Shipping instructions - ${instructionTitle}`
    : `Finalise shipping instructions - ${instructionTitle}`

  const message1 = isInstructionCreated
    ? 'This document has been already been submitted and is read-only.'
    : 'Review the content and add details where needed. The fields marked with asterisk (*) are mandatory.'

  const message2 = isInstructionCreated ? (
    <div>
      Please{' '}
      <MuiLink
        variant="body1"
        component={Link}
        to={`/shipments/${shipmentId}/chats`}
      >
        send a chat message
      </MuiLink>{' '}
      to your freight forwarder if you wish to submit another version.
    </div>
  ) : (
    'Once you are finished, click submit and the document will be uploaded to the shipment.'
  )

  return (
    <Paper className="shipping-instructions" sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'sticky',
          top: '56px',
          backgroundColor: 'var(--common-white)',
          zIndex: 1,
        }}
      >
        <Box pl={3} pr={3} pt={3} display="flex" justifyContent="space-between">
          <Typography
            variant="h3"
            gutterBottom
            data-testid="shipping-instruction-title"
          >
            {title}
          </Typography>
          <LoadingButton
            data-testid="shipping-instruction-submit-button"
            loading={isSubmitting}
            size="medium"
            variant="contained"
            disabled={isInstructionCreated}
            onClick={onSubmit}
          >
            Submit
          </LoadingButton>
        </Box>
        <Box pl={3} pr={3} pb={3}>
          <Typography gutterBottom>{message1}</Typography>
          <Typography>{message2}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'sticky',
          width: '100%',
          height: '10px',
          top: '179px',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.07)',
        }}
      />
      <Box
        sx={{
          backgroundColor: 'white',
          height: '10px',
          position: 'absolute',
          width: '100%',
        }}
      />
      <Grid container spacing={3} p={3}>
        <Grid item xs={6}>
          <ItemWrapper>
            <Typography variant="h6" gutterBottom>
              References
            </Typography>
            <Box
              sx={{
                border: '1px solid var(--grey-200)',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              {state.references.map((reference) => (
                <Box key={reference.reference_type}>
                  {formatText(reference.reference_type)}: {reference.reference}
                </Box>
              ))}
            </Box>
          </ItemWrapper>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom>
            Shipment information
          </Typography>
          <Box
            sx={{
              border: '1px solid var(--grey-200)',
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box>Port of loading: {state.port_of_loading}</Box>
            <Box>Vessel: {state.vessel_name}</Box>
            <Box>Voyage: {state.voyage_flight_no}</Box>
            <Box>Port of discharge: {state.port_of_discharge}</Box>
          </Box>
        </Grid>
        {state.parties
          .filter((party) =>
            ['notify', 'consignee', 'shipper'].includes(party.role_type)
          )
          .map((party) => (
            <Grid item xs={6} key={party.role_type}>
              <ItemWrapper>
                <Party party={party} />
              </ItemWrapper>
            </Grid>
          ))}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Containers
          </Typography>
          <table>
            <thead>
              <tr>
                <th>Marks and numbers</th>
                <th>Number of packages</th>
                <th>Goods description</th>
                <th>Weight</th>
                <th>Volume</th>
              </tr>
            </thead>
            <tbody>
              {state.cargos.map((cargo) => (
                <tr key={cargo.id}>
                  <td style={{ whiteSpace: 'pre-line' }}>
                    {cargo.marks_and_numbers}
                  </td>
                  <td width="100px">{cargo.packages}</td>
                  <td style={{ whiteSpace: 'pre-line' }}>
                    {cargo.goods_description}
                  </td>
                  <td width="100px">
                    <strong>{cargo.weight_kg}</strong>
                  </td>
                  <td width="100px">
                    <strong>{cargo.volume_cbm}</strong>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid>
        <Grid item xs={12}>
          <ControlledTextArea
            name="notes"
            label="Notes"
            minRows={3}
            disabled={isInstructionCreated}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledSelect
            name="freight_payable"
            label="Freight payable"
            options={freightPayableOptions}
            disabled={isInstructionCreated}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledTextArea
            name="freight_payable_at"
            label="Freight payable at"
            disabled={isInstructionCreated}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledSelect
            name="movement_type"
            label="Movement Type"
            options={movementTypeOptions}
            disabled={isInstructionCreated}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledTextArea
            name="issue_place"
            label="Place of issue"
            disabled={isInstructionCreated}
          />
        </Grid>
        <Grid item xs={12}>
          <table style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th>Date of issue</th>
                <th>Signature</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{formatDate(state.issue_date)}</td>
                <td>{state.signature_by}</td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ShippingInstructions
