import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MRT_Cell, MRT_RowData } from 'material-react-table'
import CircularProgress from '@mui/material/CircularProgress'
import OverviewTableText from '../OverviewTableText'
import { getCellIsEditable } from './utils'
import { SelectInput } from './styled'

export interface TextFieldCellProps<TData extends MRT_RowData> {
  cell: MRT_Cell<TData>
  testId?: string
  onClose?: (value: string | null) => void
  isLoading?: boolean
}

export const TextFieldCell = ({
  cell,
  testId = '',
  isLoading = false,
  onClose,
}: TextFieldCellProps<MRT_RowData>) => {
  const { t } = useTranslation()
  const cellIsEditable = useMemo(() => {
    return getCellIsEditable(cell)
  }, [cell])

  const sendValue = (e) => {
    if (onClose) {
      const value = (e.target as HTMLInputElement).value
      if (value === cell.getValue()) {
        return
      }
      onClose(value && value.length > 0 ? value : null)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.code === 'Tab') {
      sendValue(e)
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    sendValue(e)
  }

  if (cellIsEditable) {
    return (
      <SelectInput
        data-testid={testId}
        defaultValue={cell.getValue() ?? ''}
        className="filled-cell editable"
        placeholder={isLoading ? '' : t('common.buttons.add', 'Add')}
        fullWidth
        InputProps={{
          endAdornment: isLoading ? (
            <CircularProgress color="inherit" size={14} />
          ) : null,
        }}
        inputProps={{
          'data-testid': 'editable-text-input',
        }}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        disabled={isLoading}
      />
    )
  }
  return (
    <OverviewTableText
      data-testid={testId}
      text={cell.getValue() as string | null | undefined}
    />
  )
}
