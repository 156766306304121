import { FunctionComponent, useState, useMemo } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import simplur from 'simplur'
import { uniqBy, omit, capitalize, reduce, each, max } from 'lodash'
import {
  Button,
  Box,
  InputLabel,
  Tooltip,
  Typography,
  SelectChangeEvent,
} from '@mui/material'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import LoadingButton from '@mui/lab/LoadingButton'
import Modal from 'src/components/Common/Modal'
import { showNotification } from 'src/stores/actionCreators/notifications'
import {
  bulkEditTransporter,
  pickupsAndDeliveriesSelectedIds,
} from 'src/stores/actionCreators'
import { promisifyAction } from 'src/utils'
import {
  isValidDate,
  convertDateToOrderFormat,
  fromISOwithZone,
} from 'src/utils/helpers'
import { canBulkEdit, canUpdateTransport } from '../helpers'
import InlandTransportRowTransportTransporter from '../PickupsAndDeliveriesTable/InlandTransportRowTransportTransporter'
import InlandTransportRowTransportAddress from '../PickupsAndDeliveriesTable/InlandTransportRowTransportAddress'
import InlandTransportRowDeliveryDate from '../PickupsAndDeliveriesTable/InlandTransportRowDeliveryDate'

interface IProps {
  open: boolean
  handleClose: () => void
}

const PickupsAndDeliveriesEditWindow: FunctionComponent<IProps> = (props) => {
  const [transporter, setTransporterValue] = useState<number | null>(null)
  const [address, setAddressValue] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [date, setDateValue] = useState<string | null>(null)
  const [inspectionDate, setInspectionDateValue] = useState<string | null>(null)
  const [status, setStatus] = useState<number | undefined>(undefined)
  const dispatch = useDispatch()

  const bulkEditTransporterAsync = promisifyAction(
    dispatch,
    bulkEditTransporter
  )

  const { rowsSelected, list, scope, taskMappings } = useSelector(
    (state: IGlobalState) => ({
      rowsSelected: state.pickUpsAndDeliveries.selectedInlandTransportRowsIds,
      list: state.pickUpsAndDeliveries.list,
      scope: state.pickUpsAndDeliveries.filters.scope,
      taskMappings:
        state.pickUpsAndDeliveries.filtersOptions.task_type_statuses,
    }),
    shallowEqual
  )

  const scopeLabel = useMemo(() => {
    return scope === 'pickup' ? 'pick-up' : scope
  }, [scope])

  const showResult = (errors) => {
    if (!errors.length) {
      dispatch(
        showNotification({
          severity: 'success',
          message: simplur`Transport has been updated for ${rowsSelected.length} containe[r|rs]`,
        })
      )
    } else {
      each(errors, (error) => {
        dispatch(
          showNotification({
            severity: 'error',
            message: error.error || '',
          })
        )
      })
    }
  }

  const onSave = async (): Promise<any> => {
    setIsLoading(true)
    const params = {
      transporter_organization_id: transporter,
      address_id: address,
      datetime_from: date,
      task_status_id: status,
      delivery_inspection_datetime: inspectionDate,
    }
    try {
      const res = await bulkEditTransporterAsync({
        inland_transport_ids: rowsSelected,
        parameters: omit(params),
      })
      showResult(res.errors)
      dispatch(pickupsAndDeliveriesSelectedIds([]))
    } catch (e) {
      dispatch(
        showNotification({
          severity: 'error',
          message: 'Transport update error',
        })
      )
    }

    onClose()
    setIsLoading(false)
  }

  const onClose = (): void => {
    setTransporterValue(null)
    setAddressValue(null)
    setDateValue(null)
    setInspectionDateValue(null)
    setStatus(undefined)
    props.handleClose()
  }

  const setTransporter = (value: number) => {
    setTransporterValue(value)
  }

  const setAddress = (address) => {
    setAddressValue(!!address ? address.value : null)
  }

  const onSelectStatus = (event: SelectChangeEvent<unknown>): void => {
    const { value } = event.target
    setStatus(value as number)
  }

  const selectedCustomers = useMemo(() => {
    const allCollaborators = reduce(
      list,
      (result, item) => {
        if (rowsSelected.includes(item.id)) {
          result.push(
            (item.shipment?.collaborators || []).filter((connection) => {
              return connection.organization_role_code === 'customer'
            })
          )
        }
        return result
      },
      [] as ICollaborator[][]
    )

    return uniqBy(allCollaborators.flat(1), 'organization_id')
  }, [rowsSelected, list])

  const latestETA = useMemo(() => {
    const selectedEta = reduce(
      list,
      (result, item) => {
        if (
          rowsSelected.includes(item.id) &&
          isValidDate(item.shipment?.estimated_arrival || '')
        ) {
          result.push(item.shipment?.estimated_arrival || '')
        }
        return result
      },
      [] as string[]
    )

    return max(selectedEta) as string
  }, [rowsSelected, list])

  const formattedDate = convertDateToOrderFormat(latestETA, 'dd MMM HH:mm')

  const tasksOptions = () => {
    return (taskMappings?.FRESH_inspection || []).map((task) => {
      return {
        id: task.id,
        label: task.name,
        value: task.id,
      }
    })
  }

  const renderDateTime = (title: string) => {
    return (
      <Box width={300} mt={2}>
        <InputLabel>{title} date & time</InputLabel>
        <InlandTransportRowDeliveryDate
          service={scope}
          addresses={[]}
          arrival={
            isValidDate(latestETA) && title !== 'Inspection'
              ? fromISOwithZone(latestETA)
              : null
          }
          departure={null}
          updatable={canUpdateTransport()}
          pickupDeliverDateTime={null}
          inline={true}
          onSelectDate={
            title === 'Inspection' ? setInspectionDateValue : setDateValue
          }
          width={300}
        />
      </Box>
    )
  }

  return (
    <Modal.Window open={props.open} onClose={onClose}>
      <Modal.Title children={`Plan ${scopeLabel}`} onClose={onClose} />
      <Modal.Content>
        <Box display="flex">
          <Typography
            children="You have selected 1 or more containers with latest ETA:"
            variant="body1"
          />
          <Typography
            ml={1}
            children={formattedDate || 'TBA'}
            variant="body1Strong"
          />
        </Box>
        <Box display="flex" mt={2}>
          <Box pr={4}>
            <Typography children={capitalize(scopeLabel)} variant="h5" />
            {canUpdateTransport() && (
              <Box width={400} mt={2}>
                <InputLabel>Transporter</InputLabel>
                <InlandTransportRowTransportTransporter
                  rowId={null}
                  updatable={canUpdateTransport()}
                  transporter={null}
                  inputWidth={500}
                  inline={true}
                  onSelectValue={setTransporter}
                />
              </Box>
            )}
            {canBulkEdit() && (
              <Box width={400} mt={2} data-testid="edit-address">
                <InputLabel
                  disabled={
                    selectedCustomers.length > 1 && rowsSelected.length > 1
                  }
                >
                  {capitalize(scopeLabel)} address
                </InputLabel>
                <Tooltip
                  color="primary"
                  title={
                    selectedCustomers.length > 1
                      ? 'It is not possible to assign the same address to multiple organizations.'
                      : ''
                  }
                  placement="top"
                >
                  <div>
                    <InlandTransportRowTransportAddress
                      updatable={canBulkEdit()}
                      service={scope}
                      inputWidth={500}
                      addresses={[]}
                      inline={true}
                      collaborators={selectedCustomers}
                      onSelectValue={setAddress}
                      disabled={
                        selectedCustomers.length > 1 && rowsSelected.length > 1
                      }
                    />
                  </div>
                </Tooltip>
              </Box>
            )}
            {canUpdateTransport() && renderDateTime(capitalize(scopeLabel))}
          </Box>
          {scope === 'delivery' && canUpdateTransport() && (
            <Box pl={4} pr={8} sx={{ borderLeft: 1, borderColor: 'divider' }}>
              <Typography children="Inspection" variant="h5" />
              <Box width={300} mt={2}>
                <InputLabel disabled={!tasksOptions().length}>
                  Inspection status
                </InputLabel>
                <SingleSelect
                  value={status}
                  placeholder="Select status"
                  onChange={onSelectStatus}
                  options={tasksOptions()}
                  disabled={!tasksOptions().length}
                />
              </Box>
              {renderDateTime('Inspection')}
            </Box>
          )}
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="outlined"
          size="large"
          onClick={onClose}
          data-testid="close-button"
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color="primary"
          size="large"
          onClick={onSave}
          disabled={
            !transporter && !address && !date && !status && !inspectionDate
          }
          data-testid="save-button"
        >
          Save
        </LoadingButton>
      </Modal.Actions>
    </Modal.Window>
  )
}
export default PickupsAndDeliveriesEditWindow
