import capitalize from 'lodash/capitalize'
import Box from '@mui/material/Box'
import { queryClient } from 'src/services/http-common'
import { queryKeys } from 'src/services/endpoints'
import {
  OverviewAllowedColumnsAndFiltersResponse,
  OverviewTaskTypeResponse,
} from 'src/services/Api/overview/types'
import { TFunction } from 'i18next'
import { tableSortingKeys, tableGroups } from './constants'
import { StyledRowBadge } from './styled'
import OverviewTableText from './Table/OverviewTableText'
import OverviewTableTasks from './Table/OverviewTableTasks'
import OverviewTableShipmentLink from './Table/OverviewTableShipmentLink'
import OverviewTableVesselsGroupedCell from './Table/OverviewTableVesselsGroupedCell'
import OverviewTableAggregatedCell from './Table/OverviewTableVesselsAggregatedCell'
import OverviewTableShipmentStatusCell from './Table/OverviewTableShipmentStatusCell'
import OverviewTableContainerStatusCell from './Table/OverviewTableContainerStatusCell'
import OverviewTableFirstDayDemurrageCell from './Table/OverviewTableFirstDayDemurrageCell'
import OverviewTableContainerCustomsStatus from './Table/OverviewTableContainerCustomsStatus'
import OverviewTablePickupOrDeliveryStatusCell from './Table/OverviewTablePickupOrDeliveryStatusCell'
import OverviewTableContainerInspectionStatus from './Table/OverviewTableContainerInspectionStatus'
import OverviewTableShipmentBookingParties from './Table/OverviewTableShipmentBookingParties'
import OverviewTableShipmentClosingDateTime from './Table/OverviewTableShipmentClosingDateTime'
import OverviewTableContainerNumber from './Table/OverviewTableContainerNumber'
import { getDisplayDate, getAllowedColumnsObject } from './utils'
import TransportDateTime from './Table/EditableCells/TransportDateTime'
import ContainerDateTime from './Table/EditableCells/ContainerDateTime'
import EditableNotes from './Table/EditableCells/Notes'
import EditableTransporter from './Table/EditableCells/Transporter'
import EditableAddress from './Table/EditableCells/Address'
import ContainerEditableText from './Table/EditableCells/ContainerText'

export const getTaskTypes = (): OverviewTaskTypeResponse | undefined => {
  return queryClient.getQueryData([queryKeys.taskTypes])
}

const getDynamicColumns = (t: TFunction<'translation', undefined>) => {
  const taskTypes = getTaskTypes()

  const tableCells =
    taskTypes?.data?.map((taskType) => {
      return {
        accessorKey: `task.task_type_code.${taskType.code}`,
        accessorFn: (row) => row?.tasks ?? [],
        header: t(
          `data_table.column_headers.task_types.${taskType.code}`,
          taskType.name
        ),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          return <OverviewTableTasks cell={cell} taskType={taskType} />
        },
        size: 200,
        groups: [tableGroups.tasks],
      }
    }) ?? []

  return tableCells
}

const filterAllowedColumns = (
  columns,
  t: TFunction<'translation', undefined>
) => {
  const allowedFiltersAndColumnsResponse:
    | OverviewAllowedColumnsAndFiltersResponse
    | undefined = queryClient.getQueryData([
    queryKeys.overviewAllowedFiltersAndColumns,
  ])

  const dynamicColumns = getDynamicColumns(t)

  const allowedFiltersAndColumns = getAllowedColumnsObject(
    allowedFiltersAndColumnsResponse
  )

  return [
    ...columns.filter((column) =>
      allowedFiltersAndColumns[column.accessorKey]?.includes('view')
    ),
    ...dynamicColumns,
  ]
}

export const getTableColumns = (t: TFunction<'translation', undefined>) => {
  return filterAllowedColumns(
    [
      {
        accessorKey: tableSortingKeys.shipmentOwnerOrganizationName,
        accessorFn: (row) => row?.shipment_owner_organization_name ?? '-',
        Header: t(
          'vessels_page.data_table.column_headers.customer',
          'Customer'
        ),
        header: t('vessels_page.data_table.show_hide.customer', 'Customer'),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          return <OverviewTableText text={cell.getValue()} />
        },
        size: 164,
        groups: [tableGroups.shipment],
      },
      {
        accessorKey: tableSortingKeys.shipmentVoyageNumber,
        accessorFn: (row) => {
          return row?.shipment_vessel_voyage_no ?? '-'
        },
        Header: t(
          'vessels_page.data_table.column_headers.voyage_number',
          'Voyage'
        ),
        header: t(
          'vessels_page.data_table.show_hide.voyage_number',
          'Voyage number'
        ),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          return <OverviewTableText text={cell.getValue()} />
        },
        AggregatedCell: ({ row }) => {
          return <OverviewTableText text={row?.original.voyage_no ?? '-'} />
        },
        size: 200,
        groups: [tableGroups.vessel],
      },
      {
        accessorKey: tableSortingKeys.containerType,
        accessorFn: (row) => row?.type_name ?? '-',
        Header: t('vessels_page.data_table.column_headers.type', 'Type'),
        header: t('vessels_page.data_table.show_hide.type', 'Container type'),
        enableGrouping: false,
        Cell: ({ cell }) => {
          return <OverviewTableText text={cell.getValue()} />
        },
        size: 131,
        groups: [tableGroups.container],
      },
      {
        accessorKey: tableSortingKeys.shipmentStatus,
        accessorFn: (row) => row?.shipment_status_name ?? '-',
        Header: t(
          'vessels_page.data_table.column_headers.shipment_status',
          'Shipment status'
        ),
        header: t(
          'vessels_page.data_table.show_hide.shipment_status',
          'Shipment status'
        ),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ row, cell }) => {
          return (
            <OverviewTableShipmentStatusCell
              row={row}
              cell={cell}
              field="shipment_status"
            />
          )
        },
        size: 200,
        groups: [tableGroups.shipment],
      },
      {
        accessorKey: tableSortingKeys.containerPodCustomsDocumentsPresent,
        accessorFn: (row) => row.pod_customs_documents_present,
        Header: t(
          'vessels_page.data_table.column_headers.pod_customs_documents_present',
          'Customs documents'
        ),
        header: t(
          'vessels_page.data_table.show_hide.pod_customs_documents_present',
          'Customs documents'
        ),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ row }) => {
          return <OverviewTableContainerCustomsStatus row={row} />
        },
        size: 200,
        groups: [tableGroups.container],
      },
      {
        accessorKey: tableSortingKeys.containerNumber,
        accessorFn: (row) => row.number,
        Header: t(
          'vessels_page.data_table.column_headers.container_number',
          'Container'
        ),
        header: t(
          'vessels_page.data_table.show_hide.container_number',
          'Container number'
        ),
        enableGrouping: false,
        Cell: ({ cell, row }) => {
          return (
            <OverviewTableContainerNumber
              text={cell.getValue()}
              id={`${row.original.shipment_id}/containers`}
            />
          )
        },
        size: 179,
        groups: [tableGroups.container],
      },
      {
        accessorKey: tableSortingKeys.containerSealNumber,
        accessorFn: (row) => row?.seal_number ?? '-',
        Header: t('vessels_page.data_table.column_headers.seal', 'Seal'),
        header: t('vessels_page.data_table.show_hide.seal', 'Seal number'),
        enableGrouping: false,
        Cell: ({ cell }) => {
          return <OverviewTableText text={capitalize(cell.getValue())} />
        },
        size: 116,
        groups: [tableGroups.container],
      },
      {
        accessorKey: tableSortingKeys.containerStatus,
        accessorFn: (row) => row?.status_name ?? '-',
        Header: t(
          'vessels_page.data_table.column_headers.container_status',
          'Container status'
        ),
        header: t(
          'vessels_page.data_table.show_hide.container_status',
          'Container status'
        ),
        enableGrouping: false,
        Cell: ({ row }) => {
          return (
            <OverviewTableContainerStatusCell row={row} field="status_name" />
          )
        },
        size: 237,
        groups: [tableGroups.container],
      },
      {
        accessorKey: tableSortingKeys.shipmentTitle,
        accessorFn: (row) => row.shipment_title,
        Header: t(
          'vessels_page.data_table.column_headers.shipment',
          'Shipment'
        ),
        header: t('vessels_page.data_table.show_hide.shipment', 'Shipment'),
        enableColumnOrdering: true,
        enableGrouping: false,
        GroupedCell: ({ row, cell }) => {
          return (
            <Box className="grouped-cell">
              <StyledRowBadge badgeContent={row.leafRows.length}>
                {cell.getValue()}
              </StyledRowBadge>
            </Box>
          )
        },
        Cell: ({ cell, row }) => {
          return (
            <OverviewTableShipmentLink
              text={cell.getValue()}
              id={row.original.shipment_id}
            />
          )
        },
        size: 179,
        groups: [tableGroups.shipment],
      },
      {
        accessorKey: tableSortingKeys.shipmentVessel,
        accessorFn: (row) => row?.shipment_vessel_name ?? '-',
        Header: t('vessels_page.data_table.column_headers.vessel', 'Vessel'),
        header: t('vessels_page.data_table.show_hide.vessel', 'Vessel'),
        enableGrouping: false,
        Cell: ({ cell }) => {
          return <OverviewTableText text={cell.getValue()} />
        },
        GroupedCell: OverviewTableVesselsGroupedCell,
        size: 200,
        groups: [tableGroups.vessel],
      },
      {
        accessorKey: tableSortingKeys.shipmentSharedReference,
        accessorFn: (row) => row?.shipment_shared_reference ?? '-',
        Header: t(
          'vessels_page.data_table.column_headers.shipment_shared_reference',
          'Shared reference'
        ),
        header: t(
          'vessels_page.data_table.show_hide.shipment_shared_reference',
          'Shared reference'
        ),
        enableColumnOrdering: true,
        enableGrouping: false,
        Cell: ({ cell, row }) => {
          return (
            <OverviewTableShipmentLink
              text={cell.getValue()}
              id={`${row.original.shipment_id}`}
            />
          )
        },
        size: 200,
        groups: [tableGroups.shipment],
      },
      {
        accessorKey: tableSortingKeys.shipmentInternalReference,
        accessorFn: (row) => row?.shipment_internal_reference ?? '-',
        Header: t(
          'vessels_page.data_table.column_headers.shipment_internal_reference',
          'Internal reference'
        ),
        header: t(
          'vessels_page.data_table.show_hide.shipment_internal_reference',
          'Internal reference'
        ),
        enableColumnOrdering: true,
        enableGrouping: false,
        Cell: ({ cell, row }) => {
          return (
            <OverviewTableShipmentLink
              text={cell.getValue()}
              id={`${row.original.shipment_id}`}
            />
          )
        },
        size: 200,
        groups: [tableGroups.shipment],
      },
      {
        accessorKey: tableSortingKeys.containerDischargeAt,
        Header: t(
          'vessels_page.data_table.column_headers.discharged_at',
          'Discharged at'
        ),
        header: t(
          'vessels_page.data_table.show_hide.discharged_at',
          'Discharged at'
        ),
        enableGrouping: false,
        accessorFn: (row) => row.discharged_at,
        Cell: ({ cell, row }) => {
          return (
            <ContainerDateTime
              testId="discharge-time"
              requestParam="discharge_datetime"
              cell={cell}
              containerId={row.original.id}
            />
          )
        },
        size: 199,
        groups: [tableGroups.delivery],
      },
      {
        accessorKey: tableSortingKeys.shipmentDischargePort,
        accessorFn: (row) => row?.shipment_discharge_port_name ?? '-',
        Header: t('vessels_page.data_table.column_headers.pod', 'POD'),
        header: t(
          'vessels_page.data_table.show_hide.pod',
          'Port of discharge (POD)'
        ),
        enableGrouping: false,
        Cell: ({ cell }) => {
          return <OverviewTableText text={cell.getValue()} />
        },
        AggregatedCell: ({ row }) => {
          return (
            <OverviewTableText
              variant="body1Strong"
              text={row.original.discharge_port_name}
            />
          )
        },
        size: 155,
        groups: [tableGroups.shipment],
      },
      {
        accessorKey: tableSortingKeys.shipmentEstimatedDeparture,
        accessorFn: (row) => row.shipment_estimated_departure,
        Header: t(
          'vessels_page.data_table.column_headers.departure',
          'Departure'
        ),
        header: t('vessels_page.data_table.show_hide.departure', 'Departure'),
        enableGrouping: false,
        Cell: ({ cell }) => {
          return (
            <OverviewTableText
              text={getDisplayDate(cell.getValue(), false, true)}
            />
          )
        },
        AggregatedCell: ({ row, table }) => {
          return (
            <OverviewTableAggregatedCell
              title={t('vessels_page.field.earliest_etd', 'Earliest ETD')}
              grouping={table.getState().grouping}
              text={getDisplayDate(row.original.earliest_etd)}
            />
          )
        },
        size: 180,
        groups: [tableGroups.vessel],
      },
      {
        accessorKey: tableSortingKeys.shipmentLoadingPort,
        accessorFn: (row) => row?.shipment_loading_port_name ?? '-',
        Header: t('vessels_page.data_table.column_headers.pol', 'POL'),
        header: t(
          'vessels_page.data_table.show_hide.pol',
          'Port of loading (POL)'
        ),
        enableGrouping: false,
        Cell: ({ cell }) => {
          return <OverviewTableText text={cell.getValue()} />
        },
        AggregatedCell: ({ row }) => {
          return (
            <OverviewTableText
              variant="body1Strong"
              text={row.original.loading_port_name}
            />
          )
        },
        size: 155,
        groups: [tableGroups.shipment],
      },
      {
        accessorKey: tableSortingKeys.shipmentTerminal,
        accessorFn: (row) => row?.shipment_terminal_name ?? '-',
        Header: t(
          'vessels_page.data_table.column_headers.terminal',
          'Terminal'
        ),
        header: t('vessels_page.data_table.show_hide.terminal', 'Terminal'),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          return <OverviewTableText text={cell.getValue()} />
        },
        AggregatedCell: ({ row }) => {
          return (
            <OverviewTableText
              variant="body1Strong"
              text={row.original?.terminal_name ?? null}
            />
          )
        },
        size: 185,
        groups: [tableGroups.vessel],
      },
      {
        accessorKey: tableSortingKeys.shipmentLoadingTerminal,
        accessorFn: (row) => row?.shipment_loading_terminal_name ?? '-',
        Header: t(
          'vessels_page.data_table.column_headers.loading_terminal',
          'Loading terminal'
        ),
        header: t(
          'vessels_page.data_table.show_hide.loading_terminal',
          'Loading terminal'
        ),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          return <OverviewTableText text={cell.getValue()} />
        },
        size: 185,
        groups: [tableGroups.vessel],
      },
      {
        accessorKey: tableSortingKeys.shipmentEstimatedArrival,
        accessorFn: (row) => row.shipment_estimated_arrival,
        Header: t('vessels_page.data_table.column_headers.arrival', 'Arrival'),
        header: t('vessels_page.data_table.show_hide.arrival', 'Arrival'),
        enableGrouping: false,
        Cell: ({ cell }) => {
          return (
            <OverviewTableText
              data-testid="eta"
              text={getDisplayDate(cell.getValue(), false, true)}
            />
          )
        },
        AggregatedCell: ({ row, table }) => {
          return (
            <OverviewTableAggregatedCell
              title={t('vessels_page.field.earliest_eta', 'Earliest ETA')}
              grouping={table.getState().grouping}
              text={getDisplayDate(row.original.earliest_eta)}
            />
          )
        },
        size: 160,
        groups: [tableGroups.vessel],
      },
      {
        accessorKey: tableSortingKeys.shipmentEstimatedDischarge,
        accessorFn: (row) => row.shipment_estimated_discharge,
        Header: t(
          'vessels_page.data_table.column_headers.pod_departure',
          'POD departure'
        ),
        header: t(
          'vessels_page.data_table.show_hide.pod_departure',
          'POD departure'
        ),
        enableGrouping: false,
        Cell: ({ cell }) => {
          return <OverviewTableText text={getDisplayDate(cell.getValue())} />
        },
        AggregatedCell: ({ row, table }) => {
          return (
            <OverviewTableAggregatedCell
              title={t('vessels_page.field.earliest_pod_etd')}
              grouping={table.getState().grouping}
              text={getDisplayDate(row.original.earliest_estimated_discharge)}
            />
          )
        },
        size: 203,
        groups: [tableGroups.vessel],
      },
      {
        accessorKey: tableSortingKeys.containerPickupStatus,
        accessorFn: (row) => row?.pickup_status ?? '-',
        Header: t(
          'vessels_page.data_table.column_headers.pickup_status',
          'Pick-up status'
        ),
        header: t(
          'vessels_page.data_table.show_hide.pickup_status',
          'Pick-up status'
        ),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ row, cell }) => {
          return (
            <OverviewTablePickupOrDeliveryStatusCell
              row={row}
              field="pickup_status"
              service="pickup"
              cell={cell}
            />
          )
        },
        size: 140,
        groups: [tableGroups.pickup],
      },
      {
        accessorKey: tableSortingKeys.shipmentConsignees,
        accessorFn: (row) => row?.shipment_consignees ?? [],
        Header: t(
          'vessels_page.data_table.column_headers.shipment_consignee',
          'Consignee'
        ),
        header: t(
          'vessels_page.data_table.show_hide.shipment_consignee',
          'Consignee'
        ),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          return <OverviewTableShipmentBookingParties cell={cell} />
        },
        size: 220,
        groups: [tableGroups.shipment],
      },
      {
        accessorKey: tableSortingKeys.shipmentShippers,
        accessorFn: (row) => row?.shipment_shippers ?? [],
        Header: t(
          'vessels_page.data_table.column_headers.shipment_shipper',
          'Shipper'
        ),
        header: t(
          'vessels_page.data_table.show_hide.shipment_shipper',
          'Shipper'
        ),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          return <OverviewTableShipmentBookingParties cell={cell} />
        },
        size: 220,
        groups: [tableGroups.shipment],
      },
      {
        accessorKey: tableSortingKeys.containerPickupAddress,
        accessorFn: (row) => row?.pickup_address_name ?? null,
        Header: t(
          'vessels_page.data_table.column_headers.pickup_address',
          'Pick-up address'
        ),
        header: t(
          'vessels_page.data_table.show_hide.pickup_address',
          'Pick-up address'
        ),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return <EditableAddress cell={cell} row={row} service="pickup" />
        },
        size: 168,
        groups: [tableGroups.pickup],
      },
      {
        accessorKey: tableSortingKeys.containerDeliveryAddress,
        accessorFn: (row) => row?.delivery_address_name ?? null,
        Header: t(
          'vessels_page.data_table.column_headers.delivery_address',
          'Delivery address'
        ),
        header: t(
          'vessels_page.data_table.show_hide.delivery_address',
          'Delivery address'
        ),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return <EditableAddress cell={cell} row={row} service="delivery" />
        },
        size: 168,
        groups: [tableGroups.delivery],
      },
      {
        accessorKey: tableSortingKeys.containerPickupTime,
        accessorFn: (row) => row.pickup_time,
        Header: t(
          'vessels_page.data_table.column_headers.pickup_time',
          'Pickup date'
        ),
        header: t(
          'vessels_page.data_table.show_hide.pickup_time',
          'Pickup date'
        ),
        enableSorting: true,
        enableGrouping: false,
        Cell: ({ cell, row }) => {
          return (
            <TransportDateTime
              testId="pickup-time"
              requestParam="pickup_delivery_time"
              additionalParams={{ service: 'pickup' }}
              cell={cell}
              containerId={row.original.id}
              missingValueText={t(
                'vessels_page.data_table.add_pickup_date_time',
                'Add pickup date'
              )}
            />
          )
        },
        size: 140,
        groups: [tableGroups.pickup],
      },
      {
        accessorKey: tableSortingKeys.containerDeliveryStatus,
        accessorFn: (row) => row?.delivery_status ?? '-',
        Header: t(
          'vessels_page.data_table.column_headers.delivery_status',
          'Delivery status'
        ),
        header: t(
          'vessels_page.data_table.show_hide.delivery_status',
          'Delivery status'
        ),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ row, cell }) => {
          return (
            <OverviewTablePickupOrDeliveryStatusCell
              row={row}
              field="delivery_status"
              service="delivery"
              cell={cell}
            />
          )
        },
        size: 140,
        groups: [tableGroups.delivery],
      },
      {
        accessorKey: tableSortingKeys.containerDeliveryTime,
        accessorFn: (row) => row.delivery_time,
        Header: t(
          'vessels_page.data_table.column_headers.delivery_time',
          'Delivery date'
        ),
        header: t(
          'vessels_page.data_table.show_hide.delivery_time',
          'Delivery date'
        ),
        enableSorting: true,
        enableGrouping: false,
        Cell: ({ cell, row }) => {
          return (
            <TransportDateTime
              testId="delivery-time"
              cell={cell}
              minDate={row.original?.shipment_estimated_arrival}
              containerId={row.original.id}
              missingValueText={t(
                'vessels_page.data_table.add_delivery_date_time',
                'Add delivery date'
              )}
            />
          )
        },
        size: 140,
        groups: [tableGroups.delivery],
      },
      {
        accessorKey: tableSortingKeys.shipmentTerminalEstimatedArrival,
        accessorFn: (row) => row.shipment_terminal_estimated_arrival,
        Header: t(
          'vessels_page.data_table.column_headers.terminal_estimated_arrival',
          'Terminal ETA'
        ),
        header: t(
          'vessels_page.data_table.show_hide.terminal_estimated_arrival',
          'Terminal ETA'
        ),
        enableSorting: false,
        enableGrouping: false,
        Cell: ({ cell }) => {
          return (
            <OverviewTableText
              data-testid="terminal-eta"
              text={getDisplayDate(cell.getValue())}
            />
          )
        },
        size: 140,
        groups: [tableGroups.vessel],
      },
      {
        accessorKey: tableSortingKeys.containerGateOut,
        accessorFn: (row) => row.gate_out,
        Header: t(
          'vessels_page.data_table.column_headers.container_gate_out',
          'Container gate out'
        ),
        header: t(
          'vessels_page.data_table.show_hide.container_gate_out',
          'Container gate out'
        ),
        enableSorting: false,
        enableGrouping: false,
        Cell: ({ cell }) => {
          return (
            <OverviewTableText
              data-testid="container-gate-out"
              text={getDisplayDate(cell.getValue())}
            />
          )
        },
        size: 160,
        groups: [tableGroups.container],
      },
      {
        accessorKey: tableSortingKeys.containerPodCustomsInspectionStatus,
        accessorFn: (row) => row.pod_customs_inspection_status,
        Header: t(
          'vessels_page.data_table.column_headers.pod_customs_inspection_status',
          'Container scan'
        ),
        header: t(
          'vessels_page.data_table.show_hide.pod_customs_inspection_status',
          'Container scan'
        ),
        enableSorting: true,
        enableGrouping: false,
        Cell: ({ cell }) => {
          return (
            <OverviewTableContainerInspectionStatus value={cell.getValue()} />
          )
        },
        size: 160,
        groups: [tableGroups.container],
      },
      {
        accessorKey: tableSortingKeys.containerPodCustomsInspectionType,
        accessorFn: (row) => row.pod_customs_inspection_type,
        Header: t(
          'vessels_page.data_table.column_headers.pod_customs_inspection_type',
          'Inspection type'
        ),
        header: t(
          'vessels_page.data_table.show_hide.pod_customs_inspection_type',
          'Inspection type'
        ),
        enableSorting: true,
        enableGrouping: false,
        Cell: ({ cell }) => {
          return (
            <OverviewTableContainerInspectionStatus value={cell.getValue()} />
          )
        },
        size: 160,
        groups: [tableGroups.container],
      },
      {
        accessorKey: tableSortingKeys.containerInspectionDatetime,
        accessorFn: (row) => row.inspection_datetime,
        Header: t(
          'vessels_page.data_table.column_headers.inspection_datetime',
          'Inspection'
        ),
        header: t(
          'vessels_page.data_table.show_hide.inspection_datetime',
          'Inspection'
        ),
        enableSorting: false,
        enableGrouping: false,
        Cell: ({ cell, row }) => {
          return (
            <ContainerDateTime
              testId="inspection-time"
              requestParam="inspection_datetime"
              cell={cell}
              containerId={row.original.id}
              missingValueText={t(
                'vessels_page.data_table.add_pickup_date_time',
                'Add pickup date'
              )}
            />
          )
        },
        size: 140,
        groups: [tableGroups.container],
      },
      {
        accessorKey: tableSortingKeys.containerActualFirstDayOfDemurrage,
        accessorFn: (row) => row.actual_first_day_of_demurrage,
        Header: t(
          'vessels_page.data_table.column_headers.actual_first_day_of_demurrage',
          'First day of demurrage'
        ),
        header: t(
          'vessels_page.data_table.show_hide.actual_first_day_of_demurrage',
          'Actual first day of demurrage'
        ),
        enableSorting: false,
        enableGrouping: false,
        Cell: ({ row }) => {
          return (
            <OverviewTableFirstDayDemurrageCell
              row={row}
              field="actual_first_day_of_demurrage"
            />
          )
        },
        size: 220,
        groups: [tableGroups.container],
      },
      {
        accessorKey: tableSortingKeys.shipmentCarrierEstimatedArrival,
        accessorFn: (row) => row.shipment_carrier_estimated_arrival,
        Header: t(
          'vessels_page.data_table.column_headers.carrier_estimated_arrival',
          'Carrier ETA'
        ),
        header: t(
          'vessels_page.data_table.show_hide.carrier_estimated_arrival',
          'Carrier ETA'
        ),
        enableSorting: false,
        enableGrouping: false,
        Cell: ({ cell }) => {
          return (
            <OverviewTableText
              data-testid="carrier-eta"
              text={getDisplayDate(cell.getValue())}
            />
          )
        },
        size: 140,
        groups: [tableGroups.vessel],
      },
      {
        accessorKey: tableSortingKeys.shipmentModality,
        accessorFn: (row) => row?.shipment_modality ?? '-',
        Header: t('vessels_page.data_table.column_headers.modality'),
        header: t('vessels_page.data_table.show_hide.modality', 'Modality'),
        enableGrouping: false,
        Cell: ({ cell }) => {
          return <OverviewTableText text={capitalize(cell.getValue())} />
        },
        size: 164,
        groups: [tableGroups.shipment],
      },
      {
        accessorKey: tableSortingKeys.shipmentLoadType,
        accessorFn: (row) => row?.shipment_load_type ?? '-',
        Header: t('vessels_page.data_table.column_headers.load', 'Load'),
        header: t('vessels_page.data_table.show_hide.load', 'Load type'),
        enableGrouping: false,
        Cell: ({ cell }) => {
          return <OverviewTableText text={cell.getValue().toUpperCase()} />
        },
        size: 130,
        groups: [tableGroups.shipment],
      },
      {
        accessorKey: tableSortingKeys.masterBlNumber,
        accessorFn: (row) => row?.shipment_master_bl_number ?? '-',
        Header: t(
          'vessels_page.data_table.column_headers.master_bl',
          'Master BL/AWB'
        ),
        header: t(
          'vessels_page.data_table.show_hide.master_bl',
          'Master BL/AWB'
        ),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          return <OverviewTableText text={cell.getValue()} />
        },
        size: 164,
        groups: [tableGroups.shipment],
      },
      {
        accessorKey: tableSortingKeys.houseBlNumbers,
        accessorFn: (row) => row?.shipment_house_bl_numbers ?? [],
        Header: t(
          'vessels_page.data_table.column_headers.house_bl',
          'House BL/AWB'
        ),
        header: t('vessels_page.data_table.show_hide.house_bl', 'House BL/AWB'),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          return <OverviewTableText text={cell.getValue().sort().join(', ')} />
        },
        size: 164,
        groups: [tableGroups.shipment],
      },
      {
        accessorKey: tableSortingKeys.pickupTransporter,
        accessorFn: (row) => row?.pickup_transporter_name ?? null,
        Header: t(
          'vessels_page.data_table.column_headers.pickup_transporter',
          'Transporter (pick-up)'
        ),
        header: t(
          'vessels_page.data_table.show_hide.pickup_transporter',
          'Transporter (pick-up)'
        ),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return <EditableTransporter cell={cell} row={row} service="pickup" />
        },
        size: 200,
        groups: [tableGroups.pickup],
      },
      {
        accessorKey: tableSortingKeys.deliveryTransporter,
        accessorFn: (row) => row?.delivery_transporter_name ?? null,
        Header: t(
          'vessels_page.data_table.column_headers.delivery_transporter',
          'Transporter (delivery)'
        ),
        header: t(
          'vessels_page.data_table.show_hide.delivery_transporter',
          'Transporter (delivery)'
        ),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return (
            <EditableTransporter cell={cell} row={row} service="delivery" />
          )
        },
        size: 200,
        groups: [tableGroups.delivery],
      },
      {
        accessorKey: tableSortingKeys.shipmentClosingDateTime,
        Header: t(
          'vessels_page.data_table.column_headers.closing_datetime',
          'Closing'
        ),
        header: t(
          'vessels_page.data_table.show_hide.closing_datetime',
          'Closing date'
        ),
        enableGrouping: false,
        accessorFn: (row) => row.shipment_closing_datetime,
        Cell: ({ cell, row }) => {
          return (
            <OverviewTableShipmentClosingDateTime
              date={cell.getValue()}
              status={row?.original?.shipment_closing_datetime_status}
            />
          )
        },
        size: 180,
        groups: [tableGroups.shipment],
      },
      {
        accessorKey: tableSortingKeys.containerPickupNotes,
        accessorFn: (row) => row?.pickup_notes ?? '',
        Header: t(
          'vessels_page.data_table.column_headers.pickup_notes',
          'Notes (pick-up)'
        ),
        header: t(
          'vessels_page.data_table.show_hide.pickup_notes',
          'Notes (pick-up)'
        ),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return (
            <EditableNotes
              cell={cell}
              containerId={row.original.id}
              additionalParams={{ service: 'pickup' }}
              missingValueText={t(
                'vessels_page.data_table.add_pickup_notes',
                'Add pickup notes'
              )}
            />
          )
        },
        size: 200,
        groups: [tableGroups.pickup],
      },
      {
        accessorKey: tableSortingKeys.containerDeliveryNotes,
        accessorFn: (row) => row?.delivery_notes ?? '',
        Header: t(
          'vessels_page.data_table.column_headers.delivery_notes',
          'Notes (delivery)'
        ),
        header: t(
          'vessels_page.data_table.show_hide.delivery_notes',
          'Notes (delivery)'
        ),
        enableGrouping: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return (
            <EditableNotes
              cell={cell}
              containerId={row.original.id}
              additionalParams={{ service: 'delivery' }}
              missingValueText={t(
                'vessels_page.data_table.add_delivery_notes',
                'Add delivery notes'
              )}
            />
          )
        },
        size: 200,
        groups: [tableGroups.delivery],
      },
      {
        accessorKey: tableSortingKeys.containerSharedReference,
        accessorFn: (row) => row?.shared_reference ?? null,
        Header: t(
          'vessels_page.data_table.column_headers.shared_reference',
          'Container Shared reference'
        ),
        header: t(
          'vessels_page.data_table.show_hide.shared_reference',
          'Container Shared reference'
        ),
        enableGrouping: false,
        Cell: ({ cell, row }) => {
          return (
            <ContainerEditableText
              cell={cell}
              containerId={row.original.id}
              requestParam="shared_reference"
            />
          )
        },
        size: 240,
        groups: [tableGroups.container],
      },
    ],
    t
  )
}
