import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/bookings'

interface TaskStatus {
  id: number
  name: string
  color?: string
  task_status_type: {
    id: number
    code: string
    name: string
  }
}

declare global {
  interface IBooking {
    booking_index: string | null
    booking_parties_information_complete: boolean
    booking_events?: IBookingEvent[]
    booking_reference: string | null
    cargo_ready_date: string | null
    commercial_invoice_and_packing_list_uploaded: boolean
    consignee: IBookingParty
    consignee_as_notify_party: boolean
    house_bl: string | null
    id: number
    incoterm: string | null
    notify_party: IBookingParty
    shipment: IBookingShipment
    shipment_documents: IShipmentDocument[]
    shipper: IBookingParty
    actions_required: any[]
    purchase_orders: IPurchaseOrder[]
    url: string
    milestones: IMilestone[]
  }

  interface IBookingParty {
    shipment_address: IShipmentAddress
    shipment_contacts: IContactItem
  }

  interface IBookingShipment {
    id: number
    reference: string | null
    url: string
  }

  interface IBookingShort {
    id: number
    booking_reference: string | null
    shipper_name: string | null
    booking_index: string | null
  }

  interface IFormDataBooking {
    id: number
    shipper_name: string | null
  }
  interface IIncotermItem {
    id: number
    name: string
    priority: number
  }
  interface IIncotermRoleServicesServiceItem extends IIncotermItem {
    service_type: string
  }
  interface IIncotermRoleServices {
    id: number
    name: string
    code: string
    role_type: string
    services: IIncotermRoleServicesServiceItem[]
  }
  interface IIncoterm extends IIncotermItem {
    code: string
    role_services: IIncotermRoleServices[]
  }

  interface IBookingTemplateVariables {
    previous_value: string | null
    updated_value: string | null
    file_url?: string
    original_filename?: string
    organization_ids?: number[]
  }

  interface IBookingEvent {
    created_at: string
    template_name: string
    template_variables: IBookingTemplateVariables
    user: IAuthor
  }

  interface IMilestone {
    name: string
    reached: boolean
    tasks: IMilestoneTask[]
    booking_level: boolean
    container_level: boolean
    code: string
    due_date: string | null
    id: number
    start_date: string | null
    status: string
  }

  interface IMilestoneTask {
    document_type_code: string | null
    id: number
    booking: IBookingShort
    container: IContainerShort | null
    shipment: IShipmentShort
    created_at: string
    updated_at: string
    due_date: string
    resolved: boolean
    possible_task_statuses: TaskStatus[]
    task_status: TaskStatus
    task_type: {
      id: number
      code: string
      name: string
    }
  }

  interface IActionItem {
    description: string
    field: string[] | null
    field_name: string | null
    id: string | null
    model_name:
      | string
      | 'CarrierBookingReference'
      | 'Container'
      | 'CarrierBookingUser'
      | 'CarrierBookingOrganization'
    record: any
    value: string | null
    required: boolean
  }

  interface CarrierBookingUser {
    id: number
    contact_type: string
    user: IUser
  }

  interface ICarrierBookingOrganization {
    carrier_booking_users: CarrierBookingUser[]
    id?: number
    organization: IOrganizationShort | null
    party_role: string
  }

  interface ICarrierBookingReferences {
    expiry_date: string | null
    id: number
    issue_date: string | null
    reference: string
    reference_type: string
  }

  interface ICarrierBooking {
    action_items: IActionItem[]
    booking_state: string
    carrier_booking_organizations: ICarrierBookingOrganization[]
    carrier_booking_references: ICarrierBookingReferences[]
    comments: any
    contact_user: IUser
    id: number
    move_type: string
    payload_id: string | number | null
    per_container_release: boolean
    reference_number: string | number | null
    sender_id: string
    shipment: IOverviewShipment
    spot_rate_id: string | number | null
  }

  interface IBookingsState {
    bookings: IBooking[] | null
    containers: IFormDataBooking[] | null
    incoterms: IIncoterm[] | null
    bookingsDataReceived: boolean
    carrierBooking: ICarrierBooking | null
  }

  interface ICompanyItem {
    address_id: number | null
    address_type: string
    city: string
    comment: string | null
    country_id: number | null
    country_name: string | null
    eori_number: string | null
    id: number | null
    latitude: string | null
    longitude: string | null
    name: string
    postal_code: string
    reference: string | null
    state: string | null
    street: string
    vat_number: string
  }

  interface IContactItem {
    contact_type: string
    email: string
    id: number
    name: string
    contact_id: number | null
    phone: string
    user_id: number | null
    comment?: string
  }
}

export const initialBookingsState: IBookingsState = {
  bookings: null,
  containers: null,
  incoterms: null,
  bookingsDataReceived: false,
  carrierBooking: null,
}

const clearState: Reducer<IBookingsState, AnyAction> = (state, action) =>
  initialBookingsState

const receiveBookings: Reducer<IBookingsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  bookings: action.payload.bookings,
  containers: action.payload.containers,
  carrierBooking: action.payload.carrier_booking,
  bookingsDataReceived: true,
})

const receiveCarrierBooking: Reducer<IBookingsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  carrierBooking: action.payload,
})

const receiveIncoterms: Reducer<IBookingsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  incoterms: action.payload,
})

export default createReducer(initialBookingsState, {
  [actions.CLEAR_BOOKINGS_STATE]: clearState,
  [actions.BOOKINGS_GET_DATA_SUCCESS]: receiveBookings,
  [actions.INCOTERMS_GET_DATA_SUCCESS]: receiveIncoterms,
  [actions.BOOKINGS_CARRIER_BOOKING_GET_DATA_SUCCESS]: receiveCarrierBooking,
})
