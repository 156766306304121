import { useFormContext, Controller } from 'react-hook-form'
import { Autocomplete, Box } from '@mui/material'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import { useGetCountries } from 'src/services/Api/common'
import { MenuItem } from 'src/stories/Lab/Menu/components/MenuItem'
import { KeyboardArrowDown } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

const BookingPartiesModalFormCountry = ({ label, disabled }) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const { data } = useGetCountries({
    refetchOnMount: false,
  })

  const countries = data?.data?.countries ?? []

  return (
    <Box>
      <InputLabel required children={label} />
      <Controller
        name="shipmentAddress.country"
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Autocomplete
            value={value}
            autoHighlight
            options={countries}
            disabled={disabled}
            popupIcon={<KeyboardArrowDown />}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => {
              const { id: key, ...optionProps } = props
              return <MenuItem text={option.name} {...optionProps} key={key} />
            }}
            onChange={(_, newValue) => {
              onChange(newValue)
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={!!error}
                placeholder={t(
                  'shipment_assign_booking_parties.country.placeholder',
                  'Select country'
                )}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                  'data-testid': 'booking-parties-modal-company-country',
                }}
              />
            )}
          />
        )}
      />
    </Box>
  )
}

export default BookingPartiesModalFormCountry
