import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { datadogRum } from '@datadog/browser-rum'
import { ConnectedRouter } from 'connected-react-router'
import '../i18n'
import * as Sentry from '@sentry/react'
import {
  StyledEngineProvider,
  ThemeProvider,
  Theme,
} from '@mui/material/styles'
import { ErrorBoundary } from 'react-error-boundary'
import UnhandledExceptionPage from 'src/pages/errors/UnhandledException'
import { LOCAL_STORAGE_CREDENTIAL_KEY } from 'src/config/constants'
import { redirectToLogin } from 'src/utils/navigation'
import muiTheme from './muiTheme'
import { store, history } from './shyppleStore'
import App from './App'
import { setDefaultLocale } from './config/moment'

import './index.scss'

const root = createRoot(document.getElementById('root') as HTMLElement)

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Log out from other tabs when user signed out
window.addEventListener('storage', (event) => {
  if (
    event.key === LOCAL_STORAGE_CREDENTIAL_KEY &&
    event.oldValue &&
    !event.newValue
  ) {
    redirectToLogin()
  }
})

const isProduction: boolean = window.location.href.includes('app.shypple.com')

if (isProduction) {
  datadogRum.init({
    applicationId: '605b39d7-576f-4f63-bcbc-f4e39b760a5d',
    clientToken: 'pubff35296986e725db255781ced1def0ed',
    site: 'datadoghq.eu',
    service: 'dashboard',
    env: 'production',
    version: window.shyppleConfig.revision,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    trackLongTasks: true,
    trackResources: true,
  })
  datadogRum.startSessionReplayRecording()

  Sentry.init({
    dsn:
      'https://8752ba4626c74ce49bc754faed38fd28@o1158082.ingest.sentry.io/6272981',
    ignoreErrors: [
      'Non-Error promise rejection captured',
      'ResizeObserver loop limit exceeded',
    ],
  })
}

const onErrorCallback = (error: Error) => {
  if (isProduction) {
    const connectionStatus = navigator?.onLine ? 'online' : 'offline'
    datadogRum.addError(`${error} - connection status: ${connectionStatus}`)
  }
}

setDefaultLocale()

root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <ErrorBoundary
            FallbackComponent={UnhandledExceptionPage}
            onError={onErrorCallback}
          >
            <App />
          </ErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    </ConnectedRouter>
  </Provider>
)
