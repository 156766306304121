import { useMemo } from 'react'
import { shipmentChipColor } from 'src/pages/PickupsAndDeliveries/constants'
import { getCellIsEditable } from './EditableCells/utils'
import OverviewTableShipmentColoredCell from './OverviewTableShipmentColoredCell'
import EditableShipmentStatusSelect from './EditableCells/ShipmentStatusSelect'

const OverviewTableShipmentStatusCell = ({ cell, row, field }) => {
  const statusValue = row.original?.[`${field}_id`] ?? null
  const statusText = row.original?.[`${field}_name`] ?? null

  const isEditable = useMemo(() => getCellIsEditable(cell), [cell])

  const statusPhase = row.original?.shipment_status_phase ?? 'in_transit'

  const statusColor = shipmentChipColor[statusPhase]

  if (isEditable) {
    return (
      <OverviewTableShipmentColoredCell
        color={statusColor}
        text={statusText}
        isEditable
      >
        <EditableShipmentStatusSelect
          value={statusValue}
          shipmentId={row.original.shipment_id}
        />
      </OverviewTableShipmentColoredCell>
    )
  }

  return (
    <OverviewTableShipmentColoredCell color={statusColor} text={statusText} />
  )
}

export default OverviewTableShipmentStatusCell
