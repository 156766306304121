import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import { getPickupOrDeliveryStatusOptions } from 'src/components/Overview/Search/constants'
import { useUpdateInlandTransport } from 'src/services/Api/overview'
import { customFields, tableCellMutationKey } from '../../constants'
import { SelectInputBase } from './styled'

const EditableSelect = ({ value, additionalParams, containerId }) => {
  const { t } = useTranslation()

  const pickupOrDeliveryStatusOptions = getPickupOrDeliveryStatusOptions(t)

  const { mutateAsync: updateInlandTransport } = useUpdateInlandTransport({
    retry: 0,
  })

  const updateCell = (event: SelectChangeEvent) => {
    const selectValue = event.target.value
    if (selectValue === value) {
      return
    }
    updateInlandTransport({
      mutationKey: [tableCellMutationKey],
      params: {
        custom_fields: customFields,
        inland_transports: [
          {
            container_id: containerId,
            transport_status: selectValue,
            ...additionalParams,
          },
        ],
      },
    })
  }

  return (
    <Select
      labelId="editable-select-label"
      id="editable-select"
      data-testid="editable-select"
      value={value}
      onChange={updateCell}
      input={
        <SelectInputBase
          inputProps={{ 'data-testid': 'editable-select-input' }}
          fullWidth
        />
      }
    >
      {pickupOrDeliveryStatusOptions.map((option) => {
        return (
          <MenuItem key={option.value} value={option?.value ?? ''}>
            {option.label}
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default EditableSelect
