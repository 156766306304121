import { useState } from 'react'
import { permissionTo } from 'src/utils'
import { useTranslation } from 'react-i18next'
import { Menu, MenuItem } from '@mui/material'
import { StyledMenuButton } from './TopNavigation.styles'

interface Lang {
  code: string
  name: string
  private?: boolean
}

const languages: Lang[] = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'de',
    name: 'Deutsch',
  },
  {
    code: 'es',
    name: 'Español',
  },
  {
    code: 'fr',
    name: 'Français',
  },
  {
    code: 'nl',
    name: 'Nederlands',
  },
  {
    code: 'pt',
    name: 'Português',
  },
  {
    code: 'zh',
    name: '简体中文',
    private: true,
  },
]

const getLang = (code: string) =>
  languages.find((lang) => lang.code === code) || languages[0]

const LanguageSwitchMenuItem = ({ lang, onClick, language }) => (
  <MenuItem
    key={lang.code}
    children={lang.name}
    onClick={() => onClick(lang)}
    selected={lang.code === language.code}
  />
)

const LanguageSwitch = () => {
  const { i18n } = useTranslation()
  const [language, setLanguage] = useState(getLang(i18n.language))
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (lang?: Lang) => {
    setAnchorEl(null)
    if (!lang) return
    i18n.changeLanguage(lang.code)
    setLanguage(lang)
  }

  const filteredLanguages = languages.filter(
    (lang) => !lang.private || permissionTo('menu.account.application_filter')
  )

  return (
    <>
      <StyledMenuButton
        onClick={handleClick}
        sx={{ mr: 1 }}
        data-testid="language-switch-trigger"
      >
        {language.code.toUpperCase()}
      </StyledMenuButton>
      <Menu anchorEl={anchorEl} open={open} onClose={() => handleClose()}>
        {filteredLanguages.map((lang) => (
          <LanguageSwitchMenuItem
            lang={lang}
            key={lang.code}
            language={language}
            onClick={handleClose}
          />
        ))}
      </Menu>
    </>
  )
}

export default LanguageSwitch
