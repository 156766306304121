import { orderBy } from 'lodash'
import { TFunction } from 'i18next'
import { ShipmentDocumentsFclGroupedByContainerProps } from './types'

export const normalizeContainers = (
  containers: IContainerShort[],
  t: TFunction<'translation', undefined>
) => {
  return containers.length > 0
    ? containers
    : [
        {
          id: null,
          number: null,
          container_index: t(
            'shipment_documents.not_specified_to_a_container',
            'Not specific to a container'
          ),
        },
      ]
}

export const groupByContainer = (
  acc: Record<string, ShipmentDocumentsFclGroupedByContainerProps>,
  document: IShipmentDocument,
  t: TFunction<'translation', undefined>
) => {
  const containers =
    document.containers.length > 0
      ? document.containers
      : normalizeContainers(document.containers, t)

  containers.forEach((container) => {
    const containerId = container.id ?? 'unspecified'

    if (!acc[containerId]) {
      const name = container?.number ?? container?.container_index

      acc[containerId] = {
        name,
        containerIndex: container?.container_index ?? null,
        documents: [],
        id: containerId,
        key: containerId,
      }
    }

    acc[containerId].documents.push({
      ...document,
      containers: [container],
    })
  })

  return acc
}

export const getDocumentGroups = (
  sortedDocuments: IShipmentDocument[],
  t: TFunction<'translation', undefined>
) => {
  return Object.values(
    sortedDocuments.reduce<
      Record<string, ShipmentDocumentsFclGroupedByContainerProps>
    >((acc, document) => groupByContainer(acc, document, t), {})
  )
}

export const sortByContainerIndex = (
  groups: ShipmentDocumentsFclGroupedByContainerProps[]
): ShipmentDocumentsFclGroupedByContainerProps[] => {
  return orderBy(groups, ['containerIndex'], ['asc'])
}

export const sortDocumentsByShipperName = (
  group: ShipmentDocumentsFclGroupedByContainerProps
) => {
  const sortedDocuments = orderBy(
    group.documents,
    [(document: IShipmentDocument) => document?.booking?.shipper_name],
    ['asc']
  )
  return { ...group, documents: sortedDocuments }
}

export const getSortedDocuments = (
  documents: IShipmentDocument[],
  t: TFunction<'translation', undefined>
): ShipmentDocumentsFclGroupedByContainerProps[] => {
  const groups = getDocumentGroups(documents, t)

  const sortedByContainer = sortByContainerIndex(groups)

  return sortedByContainer.map(sortDocumentsByShipperName)
}
