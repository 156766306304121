import { useUpdateInlandTransport } from 'src/services/Api/overview'
import { customFields, tableCellMutationKey } from '../../constants'
import { DateTimeCell, DateTimeCellProps } from './DateTime'

interface TransportDateTimeProps extends DateTimeCellProps {
  containerId: number
  requestParam?: string
  additionalParams?: object
}

const TransportDateTime = (props: TransportDateTimeProps) => {
  const {
    mutateAsync: updateInlandTransport,
    isLoading,
  } = useUpdateInlandTransport({
    retry: 0,
  })

  const {
    containerId,
    requestParam = 'pickup_delivery_time',
    additionalParams = {
      service: 'delivery',
    },
    ...otherProps
  } = props

  const onClose = (formattedSelectedValue: string | null) => {
    updateInlandTransport({
      mutationKey: [tableCellMutationKey],
      params: {
        custom_fields: customFields,
        inland_transports: [
          {
            container_id: containerId,
            [requestParam]: formattedSelectedValue,
            ...additionalParams,
          },
        ],
      },
    })
  }

  return (
    <DateTimeCell {...otherProps} onClose={onClose} isLoading={isLoading} />
  )
}

export default TransportDateTime
