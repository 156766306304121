import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ShipmentDocument from 'src/components/ShipmentDocument'
import { permissionTo } from 'src/utils'
import { Fragment, useMemo } from 'react'
import ShipmentDocumentsTableHeader from './ShipmentDocumentsTableHeader'
import { getSortedDocuments } from './utils'

const ShipmentDocumentsFclGroupedByContainer = ({
  showPublicLink,
  sortedDocuments,
  shipmentDocumentsData,
  onEditDocument,
  onDeleteDocument,
}) => {
  const { t } = useTranslation()

  const sortedGroups = useMemo(() => {
    return getSortedDocuments(sortedDocuments, t)
  }, [sortedDocuments])

  return (
    <Table>
      <ShipmentDocumentsTableHeader
        groupByContainer={true}
        showContainer={true}
      />
      <TableBody>
        {sortedGroups.map((group) => (
          <Fragment key={group.key}>
            <TableRow>
              <TableCell
                colSpan={7}
                sx={(theme) => ({
                  backgroundColor: theme.palette.grey[50],
                })}
              >
                <Typography variant="body1Strong">{group.name}</Typography>
              </TableCell>
            </TableRow>
            {group.documents.map((document) => (
              <ShipmentDocument
                key={document.id}
                document={document}
                isModalityAir={false}
                shipmentId={shipmentDocumentsData.id}
                onEdit={onEditDocument}
                onDelete={onDeleteDocument}
                isFcl={true}
                downloadable={permissionTo('shipments.documents.download')}
                containersCount={shipmentDocumentsData?.containers?.length ?? 0}
                disabled={
                  !document.document_manageable ||
                  !permissionTo('shipments.documents.upload')
                }
                withBooking={true}
                showPublicLink={showPublicLink}
                isGroupedByContainer={true}
              />
            ))}
          </Fragment>
        ))}
      </TableBody>
    </Table>
  )
}

export default ShipmentDocumentsFclGroupedByContainer
