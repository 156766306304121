import { createQueryKeys } from './utils'

const endpoints = {
  userAccount: 'account',

  // Sigma
  sigmaReports: 'sigma_reports',

  // Hubspot
  identification: 'hubspot_identification',

  // Dashboard
  dashboardPickupDelivery: 'dashboard/pickup_delivery',
  dashboardUpdatedByOthers: 'dashboard/updated_by_others',
  dashboardTasks: 'dashboard/tasks',
  dashboardTaskManager: 'dashboard/task_manager',

  // Maps
  maps: 'maps',

  // Demurrage and Detention
  demurrageAndDetention: 'demurrage_and_detention_prices',

  // Shipments
  shipments: 'shipments',
  shipmentOverview: 'shipments',
  shipmentBookings: 'shipments',
  shipmentContainers: 'shipments',
  shipmentsFiltersData: 'shipments/filters_data',
  shipmentsVisibilityShipmentCarriers: 'shipments/visibility_shipment_carriers',
  shipmentsMeta: 'shipments/meta',
  shipmentsUniqueTrackingKey: 'shipments/validate_tracking_key',
  shipmentAddresses: 'shipments/shipment_address_uniq_names',

  // Common
  commonTaskStatusTypes: 'common/task_status_types',
  commonAutoComplete: 'common/autocomplete',
  commonCarriers: 'common/carriers',
  commonAddresses: 'addresses',
  commonContacts: 'contacts',
  commonConnections: 'connections',
  commonOrganizationShipmentRoles: 'common/organization_shipment_roles',
  commonIncoterms: 'common/incoterms',
  commonProductGroups: 'common/product_groups',
  commonContainerTypes: 'container_types',
  commonCountries: 'common/countries',
  commonPackageTypes: 'common/package_types',
  commonShipmentRolesSuggestion: 'shipment_roles_suggestion',
  commonShipmentStatuses: 'common/shipment_statuses',
  commonRecentSearches: 'recent_searches',

  // Saved_filters
  savedFilters: 'saved_filters',
  savedFiltersBulkUpdate: 'saved_filters/bulk_update',

  // Organizations
  organizations: 'organizations',
  organizationsHSCodes: 'organizations/hs_codes',

  // Prices
  prices: 'prices',
  pricesFilterOptions: 'prices/filter_options',
  pricesSetUserContainerTypes: 'prices/set_user_container_types',

  // containerTypes
  containerTypes: 'container_types',

  // Pickups_deliveries
  pickupsDeliveriesFilterOptions: 'pickups_deliveries/filter_options',

  // Shipment_templates
  shipmentTemplates: 'shipment_templates',
  shipmentTemplate: 'shipment_template',
  shipmentTemplateCreateShipment: 'shipment_templates/create_shipment',

  // Overview
  inlandTransportsOverview: 'overview/inland_transports',
  shipmentsOverview: 'overview/shipments',
  containersOverview: 'overview/containers',
  vesselsOverview: 'overview/vessels',
  overviewAllowedFiltersAndColumns: 'overview/filters_columns',
  taskTypes: 'task_types',
  tasks: 'overview/tasks',

  // Password
  changePassword: '/auth/password',

  // Clusters
  clusters: '/clusters',
}

const queryKeys = createQueryKeys(endpoints)

export default endpoints
export { endpoints, queryKeys }
