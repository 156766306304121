import { useGetAutocompleteAsync } from 'src/services/Api/common'
import { useTranslation } from 'react-i18next'
import { IPlaceSuggestionResponse } from 'src/@types/endpoints/common'
import { FilterOptionValue } from 'src/components/Common/AutocompleteWrappers/types'
import { getFormattedPorts, getFormattedRecentPorts } from './utils'
import BaseAutocompleteWrapper from './index'

interface AsyncPortSelectProps {
  name: 'pol' | 'pod'
  defaultValue: FilterOptionValue[]
  WrappedAutocompleteComponent: React.ComponentType<any>
}

const AsyncPortSelect = (props: AsyncPortSelectProps) => {
  const { t } = useTranslation()
  return (
    <BaseAutocompleteWrapper
      name={props.name}
      names={['pol', 'pod']}
      fetchFunction={useGetAutocompleteAsync}
      formatter={(data: IPlaceSuggestionResponse) =>
        getFormattedPorts(data, t('components.autocomplete.ports', 'Ports'))
      }
      defaultValue={props.defaultValue?.map((item) => {
        return {
          ...item,
          group: t('components.autocomplete.ports', 'Ports'),
        }
      })}
      params={{ only_ports: true, with_ports: true }}
      WrappedAutocompleteComponent={props.WrappedAutocompleteComponent}
      recentSearchesFormatter={getFormattedRecentPorts}
    />
  )
}

export default AsyncPortSelect
