import { useUpdateContainer } from 'src/services/Api/overview'
import { customFields, tableCellMutationKey } from '../../constants'
import { DateTimeCell, DateTimeCellProps } from './DateTime'

interface ContainerDateTimeProps extends DateTimeCellProps {
  containerId: number
  requestParam: string
}

const ContainerDateTime = (props: ContainerDateTimeProps) => {
  const { mutateAsync: updateInlandContainer, isLoading } = useUpdateContainer({
    retry: 0,
  })

  const { containerId, requestParam, ...otherProps } = props

  const onClose = (formattedSelectedValue: string | null) => {
    updateInlandContainer({
      mutationKey: [tableCellMutationKey],
      params: {
        custom_fields: customFields,
        containers: [
          {
            id: containerId,
            [requestParam]: formattedSelectedValue,
          },
        ],
      },
    })
  }

  return (
    <DateTimeCell {...otherProps} onClose={onClose} isLoading={isLoading} />
  )
}

export default ContainerDateTime
