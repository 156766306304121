import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Box, Paper } from '@mui/material'
import { Table } from 'src/stories'
import Chip from '@mui/material/Chip'
import {
  containerColumnsData,
  reeferSettingsColumnsData,
} from '../../constants'
import { ShipmentInformationCard } from './ShipmentInformationCard'

type IProps = {
  inlandTransport: ISingleInlandTransport
}

const ShipmentInformation: FunctionComponent<IProps> = ({
  inlandTransport,
}) => {
  const { shipment } = inlandTransport
  const { t } = useTranslation()
  const renderTable = (columns, records) => {
    return (
      <Table
        component={Paper}
        loading={false}
        columns={columns}
        rows={records}
        size={'small'}
      />
    )
  }

  return (
    <>
      <ShipmentInformationCard {...shipment} />
      <Box sx={{ mt: 3, mb: 2 }}>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="h5">
            {t(
              'vessels_page.data_table.column_headers.container_number',
              'Container'
            )}
          </Typography>
          {!!inlandTransport.container.status && (
            <Chip
              size="small"
              label={inlandTransport.container.status.name}
              color="primary"
              sx={{ ml: 1.5 }}
            />
          )}
        </Box>
        <Box sx={{ mr: 1, ml: 1, width: 650 }}>
          {renderTable(containerColumnsData, [inlandTransport.container])}
        </Box>
      </Box>
      {!inlandTransport.container?.non_operating_reefer && (
        <Box sx={{ mt: 3, mb: 2 }}>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="h5">
              {t(
                'common.forms.fields.reefer_settings.label',
                'Reefer settings'
              )}
            </Typography>
          </Box>
          <Box sx={{ mr: 1, ml: 1, width: 650 }}>
            {renderTable(reeferSettingsColumnsData, [
              inlandTransport.container,
            ])}
          </Box>
        </Box>
      )}
    </>
  )
}

export default ShipmentInformation
