import { styled, TextareaAutosize } from '@mui/material'

export const StyledTextArea = styled(TextareaAutosize)(({ theme }) => {
  return {
    maxWidth: 400,
    width: '100%',
    minHeight: 24,
    lineHeight: '24px',
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.grey[400],
    padding: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    resize: 'none',
    '&:focus, &:active': {
      borderColor: theme.palette.primary.main,
      outline: 'none',
    },
    '&.error': {
      borderColor: theme.palette.error.main,
    },
  }
})
