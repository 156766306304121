import { Route, Switch } from 'react-router'
import { permissionTo } from 'src/utils'
import { Redirect } from 'react-router-dom'
import DocumentsManager from 'src/pages/DocumentsManager'
import Dashboard from 'src/pages/Dashboard'
import SearchAndBook from 'src/pages/SearchAndBook'
import ReportsDashboard from 'src/pages/ReportsDashboard'
import Accounts from 'src/pages/Account'
import OrganizationSettings from 'src/pages/OrganizationSettings'
import ShipmentSettings from 'src/pages/ShipmentSettings'
import PickupsAndDeliveries from 'src/pages/PickupsAndDeliveries'
import ShipmentLayout from 'src/pages/ShipmentLayout'
import CarrierBooking from 'src/pages/ShipmentLayout/CarrierBooking'
import AddressBookEdit from 'src/pages/AddressBookEdit'
import AddressBook from 'src/pages/AddressBook'
import PurchaseOrders from 'src/pages/PurchaseOrders'
import Shipments from 'src/pages/Shipments'
import ConnectionsView from 'src/pages/ConnectionsView'
import RequestLocalHandling from 'src/pages/RequestLocalHandling'
import Quotes from 'src/pages/Quotes'
import MessageCenter from 'src/pages/MessageCenter'
import TrackAndTrace from 'src/pages/TrackAndTrace'
import Rates from 'src/pages/Rates'
import SABQuote from 'src/components/SearchAndBook/SABQuote'
import DemurrageAndDetention from 'src/pages/DemurrageAndDetention'
import TemplatesList from 'src/pages/Templates/List'
import TemplatesCreate from 'src/pages/Templates/Create'
import TemplateEdit from 'src/pages/Templates/Edit'
import TemplateRebook from 'src/pages/Templates/Rebook'
import Overview from 'src/pages/Overview'
import Sigma from 'src/pages/Sigma'
import ShippingInstructions from './pages/ShippingInstructions'

const Routes = () => (
  <Switch>
    <Route path="/" exact component={Dashboard} />
    <Route path="/dashboard" component={Dashboard} />
    {permissionTo('reports_dashboard.view') && (
      <Route path="/operations-dashboard" component={ReportsDashboard} />
    )}
    {permissionTo('document.view') && (
      <Route path="/documents" component={DocumentsManager} />
    )}
    <Route path="/message_centre/" component={MessageCenter} />
    {permissionTo('shipping_instructions.manage') && (
      <Route
        path="/shipments/:shipment_id/instructions/:booking_id"
        component={ShippingInstructions}
      />
    )}
    <Route
      path="/shipments"
      render={({ match: { url } }) => (
        <>
          <Route exact path={`${url}/`} component={Shipments} />
          {permissionTo('carrier_bookings.view') &&
            permissionTo('carrier_bookings.create') &&
            permissionTo('carrier_bookings.update') && (
              <Route
                path={`${url}/:id/carrier-booking`}
                component={CarrierBooking}
              />
            )}
          <Route path={`${url}/:id(\\d+)`} component={ShipmentLayout} />
          <Route exact path={`${url}/new`} component={Shipments} />
        </>
      )}
    />
    {permissionTo('your_quotes.all') && (
      <Route path="/quotes" component={Quotes} />
    )}
    {permissionTo('track_and_trace.all') && (
      <Route path="/map_overviews" component={TrackAndTrace} />
    )}
    <Route path="/vessels" component={Overview} />

    {permissionTo([
      'pickups_deliveries.limited_manage',
      'pickups_deliveries.full_manage',
      'pickups_deliveries.shypple_admin',
      'pickups_deliveries.view_only',
    ]) && <Route path="/transports" component={PickupsAndDeliveries} />}
    <Route
      exact
      path="/pick_ups_and_deliveries"
      render={() => <Redirect to="/transports" />}
    />
    {permissionTo('organization_settings.view') && (
      <Route path="/organization_settings" component={OrganizationSettings} />
    )}
    {permissionTo('users.manage') && (
      <Route path="/shipment_settings/:id" component={ShipmentSettings} />
    )}
    {permissionTo('purchase_orders.view') && (
      <Route
        path={['/purchase-orders', '/order-management']}
        component={PurchaseOrders}
      />
    )}
    {permissionTo('your_rates.all') && (
      <Route path="/rates" component={Rates} />
    )}
    {permissionTo('your_reports.all') && (
      <Route path="/reports" component={Sigma} />
    )}
    {permissionTo('search_and_book.request_handling.all') && (
      <Route path="/request-handling" component={RequestLocalHandling} />
    )}
    <Route
      exact
      path="/template/new"
      render={() => <Redirect to="/templates/create" />}
    />
    <Route
      exact
      path="/template/:id/rebook"
      render={() => <Redirect to="/templates/:id/rebook" />}
    />
    <Route
      exact
      path="/template/:id/edit"
      render={() => <Redirect to="/templates/:id/edit" />}
    />
    <Route path="/account" component={Accounts} />
    <Route
      path="/templates"
      render={({ match: { url } }) => (
        <>
          <Route exact path={`${url}/`} component={TemplatesList} />
          {permissionTo('shipment_templates.create') && (
            <>
              <Route path={`${url}/create`} component={TemplatesCreate} />
              <Route path={`${url}/:id/edit`} component={TemplateEdit} />
            </>
          )}
          {permissionTo('shipment_templates.view') && (
            <>
              <Route path={`${url}/:id/rebook`} component={TemplateRebook} />
            </>
          )}
        </>
      )}
    />
    {permissionTo('address_book.manage') && (
      <Route
        path={[
          '/addresses/new',
          '/addresses/:id/edit',
          '/contacts/new',
          '/contacts/:id/edit',
        ]}
        component={AddressBookEdit}
      />
    )}
    {permissionTo('address_book.manage') && (
      <Route path="/contacts/connections/:id" component={ConnectionsView} />
    )}
    {permissionTo('address_book.view') && (
      <Route path="/contacts" component={AddressBook} />
    )}
    {permissionTo('search_and_book.all') && (
      <Route path="/search/quote" component={SABQuote} />
    )}
    {permissionTo('search_and_book.all') && (
      <Route path="/search" component={SearchAndBook} />
    )}
    {permissionTo('demurrage_and_detention_prices.view') && (
      <Route path="/demurrage-detention" component={DemurrageAndDetention} />
    )}
    <Redirect path="*" to="/404" />
  </Switch>
)

export default Routes
