import { createTheme } from '@mui/material/styles'
import capitalize from 'lodash/capitalize'
import CloseIcon from '@mui/icons-material/Close'
import { Fade } from '@mui/material'
import grey from './theme/colors/grey'
import common from './theme/colors/common'
import info from './theme/colors/light/info'
import error from './theme/colors/light/error'
import primary from './theme/colors/light/primary'
import success from './theme/colors/light/success'
import warning from './theme/colors/light/warning'
import secondary from './theme/colors/light/secondary'
import { ExtendedTypographyOptions } from './@types/declarations'

const primaryMainColor = primary[900]

const fontsStyles = {
  h1: {
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: '40px',
  },
  h2: {
    fontSize: '27px',
    fontWeight: 600,
    lineHeight: '32px',
  },
  h3: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
  },
  h4: {
    fontSize: '21px',
    fontWeight: 600,
    lineHeight: '32px',
  },
  h5: {
    fontSize: '19px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  h6: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  subtitle1: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  body1: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  overline: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0.48px',
  },
  button: {
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: '20px',
  },
}

const defaultTheme = createTheme({
  palette: {
    grey,
    common,
    primary: {
      ...primary,
      light: primary[50],
      main: primaryMainColor,
      dark: primaryMainColor,
    },
    secondary: {
      ...secondary,
      light: secondary[50],
      main: secondary[500],
      dark: secondary[900],
    },
    info: {
      ...info,
      light: info[50],
      main: info[600],
      dark: info[800],
    },
    warning: {
      ...warning,
      light: warning[50],
      main: warning[700],
      dark: warning[800],
    },
    success: {
      ...success,
      light: success[50],
      main: success[600],
      dark: success[900],
    },
    error: {
      ...error,
      light: error[50],
      main: error[700],
      dark: error[900],
    },
    text: {
      disabled: grey[400],
      secondary: grey[600],
      primary: primaryMainColor,
    },
  },
  shape: {
    borderRadius: 5,
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    body1Strong: {
      ...fontsStyles.body1,
      fontWeight: 600,
    },
    body1Medium: {
      ...fontsStyles.body1,
      fontWeight: 500,
    },
    subtitle1Medium: {
      ...fontsStyles.subtitle1,
      fontWeight: 500,
    },
  } as ExtendedTypographyOptions,
})

const muiTheme = createTheme({
  ...defaultTheme,
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginBottom: defaultTheme.spacing(1),
          fontSize: 15,
          fontWeight: 600,
          display: 'flex',
          color: primaryMainColor,
          alignItems: 'baseline',
          '&.Mui-disabled': {
            color: grey[400],
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: grey[200],
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation0: {
          boxShadow: 'none',
        },
        elevation1: {
          boxShadow: `0px 2px 6px rgba(0, 0, 0, 0.07)`,
        },
        outlined: {
          border: `1px solid ${grey[50]}`,
        },
        rounded: {
          borderRadius: '8px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: grey[300],
        },
        root: {
          '&.prospect, &.customer, &.consignee, &.notify_party': {
            backgroundColor: info[500],
          },
          '&.shypple_admin': {
            backgroundColor: primaryMainColor,
          },
          '&.supplier, &.third_party_organization, &.shipper, &.third_party_shipment': {
            backgroundColor: success[500],
          },
          '&.agent, &.origin_forwarder, &.lead_forwarder, &.destination_forwarder': {
            backgroundColor: warning[300],
          },
          '&.financial_institution, &.financial_institution_shipment': {
            backgroundColor: warning[600],
          },
          '&.shypple_integration': {
            backgroundColor: error[500],
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: primaryMainColor,
          height: 3,
        },
        scrollButtons: {
          '&.Mui-disabled': {
            opacity: 0.3,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up('xs')]: {
            minWidth: 0,
            padding: '6px 32px',
          },
          fontSize: 16,
          fontWeight: 500,
          textTransform: 'initial',
          color: primaryMainColor,
          '&.MuiTab-textColorInherit.Mui-selected': {
            color: primaryMainColor,
            fontWeight: 600,
          },
          '&:hover': {
            color: primaryMainColor,
          },
        },
        textColorInherit: {
          color: primaryMainColor,
          opacity: 1,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        // temporary, because some fields like date picker needs more vertical space, remove when date picker will be fixed
        paper: {
          minHeight: '56vh',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '>:not(:first-of-type)': {
            marginLeft: 16,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: primaryMainColor,
          ...fontsStyles.h3,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          marginLeft: 0,
          marginRight: 0,
          '&.outline:hover': {
            backgroundColor: grey.A100,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textUnderlineOffset: '2px',
          textDecorationColor: 'inherit',
          textDecorationThickness: '1px',
          // to align link + icon
          '& .MuiSvgIcon-root': {
            marginBottom: '-5px',
            marginRight: defaultTheme.spacing(0.5),
            marginLeft: defaultTheme.spacing(0.5),
            width: 20,
            height: 20,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '5px',
          boxShadow: 'none',
          fontWeight: 600,
          fontSize: '15px',
          lineHeight: 1.5,
          ':disabled': {
            backgroundColor: grey[200],
            color: grey[400],
          },
        },
        sizeLarge: {
          padding: '9px 16px',
        },
        outlinedSizeLarge: {
          padding: '8px 19px',
        },
        sizeMedium: {
          padding: '5px 16px',
        },
        outlinedSizeMedium: {
          padding: '4px 15px',
        },
        sizeSmall: {
          padding: '4px 16px',
        },
        outlinedSizeSmall: {
          padding: '3px 15px',
        },
        textPrimary: {
          ':hover': {
            backgroundColor: primary.A100,
          },
          ':disabled': {
            backgroundColor: common.white,
            color: grey[400],
          },
        },
        outlined: {
          borderColor: primaryMainColor,
          ':hover': {
            borderColor: primaryMainColor,
          },
        },
        outlinedPrimary: {
          borderColor: primary[200],
          ':hover': {
            backgroundColor: primary.A100,
          },
          ':disabled': {
            backgroundColor: common.white,
            borderColor: grey[300],
            color: grey[400],
          },
        },
        containedPrimary: {
          ':hover': {
            backgroundColor: primary[500],
            boxShadow: 'none',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeLarge: {
          padding: defaultTheme.spacing(1.25),
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          '&.no-scroll': {
            maxHeight: 'unset',
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          tableLayout: 'fixed',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& th': {
            fontWeight: 600,
            fontSize: 12,
            textTransform: 'uppercase',
            letterSpacing: '0.03em',
            borderBottomColor: grey[200],
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.bordered-table-row-element': {
            borderBottom: `1px solid ${grey[300]}`,
          },
          '&.warning': {
            backgroundColor: warning[50],
          },
          '&.success': {
            backgroundColor: success[50],
          },
        },
        hover: {
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: `${secondary[50]} !important`,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          minHeight: 40,
          padding: '0 12px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: 110,
          color: primaryMainColor,
          fontSize: '15px',
          '&.MuiTableCell-sizeSmall': {
            '&:not(.data-table-cell)': {
              height: 40,
            },
          },
          '&.MuiTableCell-sizeMedium': {
            '&:not(.data-table-cell)': {
              height: 52,
            },
          },
          '&.no-overflow': {
            overflow: 'unset',
          },
          '&.left-aligned': {
            justifyContent: 'flex-start',
            textAlign: 'left',
          },
          '&.right-aligned': {
            justifyContent: 'flex-end',
            textAlign: 'right',
          },
          '&.center-aligned': {
            justifyContent: 'flex-center',
            textAlign: 'center',
          },
          '&.increased-margin': {
            marginLeft: 60,
          },
          '&.full-width': {
            width: '100%',
          },
          '&.medium': {
            width: 130,
          },
          '&.x-medium': {
            width: 170,
          },
          '&.s-medium': {
            width: 100,
          },
          '&.s-wide': {
            width: 200,
          },
          '&.wide': {
            width: 230,
          },
          '&.x-wide': {
            width: 270,
            maxWidth: 270,
          },
          '&.short-50': {
            width: 50,
          },
          '&.short': {
            width: 30,
          },
          '&.x-short': {
            width: 10,
          },
          '&.bold-text': {
            fontWeight: 'bold',
          },
          '&.no-padding-and-border': {
            padding: 0,
            borderBottom: 'none',
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: 15,
          fontWeight: 400,
          padding: '0px 11px',
          height: 40,
          textTransform: 'none',
          border: `1px solid ${grey[400]}`,
          color: grey[600],
          '& .MuiSvgIcon-root': {
            marginRight: 11,
          },
          '&.Mui-selected': {
            borderColor: primaryMainColor,
            borderLeft: `1px solid ${primaryMainColor} !important`,
            borderRight: `1px solid ${primaryMainColor} !important`,
            color: `${primaryMainColor} !important`,
            backgroundColor: `${primary[50]} !important`,
          },
          '&.Mui-disabled': {
            pointerEvents: 'none',
            color: `${grey[400]} !important`,
            borderColor: `${grey[400]} !important`,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: primaryMainColor,
        },
        h1: fontsStyles.h1,
        h2: fontsStyles.h2,
        h3: fontsStyles.h3,
        h4: fontsStyles.h4,
        h5: fontsStyles.h5,
        h6: fontsStyles.h6,
        subtitle1: fontsStyles.subtitle1,
        body1: fontsStyles.body1,
        overline: fontsStyles.overline,
        button: { ...fontsStyles.button, textTransform: 'none' },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: grey[600],
          '&:hover': {
            backgroundColor: 'inherit',
          },
          '& .MuiSvgIcon-root': {
            width: 24,
            height: 24,
          },
          '& .MuiSvgIcon-fontSizeSmall': {
            width: 17,
            height: 17,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: primaryMainColor,
          '&.Mui-disabled': {
            pointerEvents: 'none',
          },
        },
        input: {
          '&::placeholder': {
            color: grey[600],
            opacity: 1,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: grey[600],
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          margin: '0 0 10px 0',
          ...['secondary', 'info', 'success', 'warning', 'error'].reduce(
            (res, color) => ({
              ...res,
              [`&.MuiAlert-filled${capitalize(color)}`]: {
                backgroundColor: defaultTheme.palette[color].light,
                border: `${defaultTheme.palette[color].main} 1px solid`,
                color: defaultTheme.palette[color].dark,
                padding: `${defaultTheme.spacing(1)} ${defaultTheme.spacing(
                  2
                )}`,
                '& .MuiAlertTitle-root': {
                  fontWeight: 600,
                },
                '& .MuiSvgIcon-root': {
                  color: 'inherit',
                },
                '& .MuiAlert-message, .MuiAlert-action, .MuiTypography-root.actions': {
                  padding: defaultTheme.spacing(0),
                  color: defaultTheme.palette[color].dark,
                  fontWeight: 400,
                  display: 'flex',
                  alignItems: 'center',
                },
                '& .MuiLink-root': {
                  marginRight: defaultTheme.spacing(2),
                  '&:hover': {
                    cursor: 'pointer',
                  },
                },
              },
            }),
            {}
          ),
          '& .MuiAlert-icon': {
            opacity: 1,
          },
        },
      },
    },
    MuiChip: {
      defaultProps: {
        color: 'secondary',
        deleteIcon: <CloseIcon />,
      },
      styleOverrides: {
        root: {
          fontSize: 15,
          fontWeight: 500,
          '&:active, &:focus': {
            boxShadow: 'none',
          },

          ...[
            'primary',
            'secondary',
            'info',
            'success',
            'warning',
            'error',
          ].reduce(
            (res, color) => ({
              ...res,
              [`&.MuiChip-filled${capitalize(
                color
              )}, &.MuiChip-outlined${capitalize(
                color
              )}, &.MuiChip-filledDefault`]: {
                '& .MuiChip-label': {
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: defaultTheme.palette[color].dark,
                },
                '&.MuiChip-clickable, &.MuiChip-deletable': {
                  '&:hover': {
                    backgroundColor: defaultTheme.palette[color][100],
                  },
                },
              },
              [`&.MuiChip-filled${capitalize(color)}`]: {
                backgroundColor: defaultTheme.palette[color].light,
                color: defaultTheme.palette[color].dark,

                '&.no-label .MuiChip-label': {
                  paddingLeft: defaultTheme.spacing(0.5),
                  paddingRight: defaultTheme.spacing(0.5),
                },

                [`& .MuiChip-avatarColor${capitalize(color)}`]: {
                  color: 'inherit',
                  backgroundColor: 'transparent !important',
                },

                '& .MuiChip-deleteIcon': {
                  color: defaultTheme.palette[color].dark,
                  fontSize: 20,
                  '&:focus, &:active': {
                    color: defaultTheme.palette[color].dark,
                  },
                },
              },
              [`&.MuiChip-filledDefault`]: {
                backgroundColor: defaultTheme.palette.grey[100],
                color: defaultTheme.palette.grey[800],
                BorderColor: defaultTheme.palette.grey[600],
                '& .MuiChip-label': {
                  color: `${grey[800]} !important`,
                },
                '& .MuiChip-deleteIcon': {
                  color: defaultTheme.palette.grey[800],
                  '&:focus, &:active': {
                    color: defaultTheme.palette.grey[800],
                  },
                },
                '&:hover': {
                  backgroundColor: `${defaultTheme.palette.grey[200]} !important`,
                },
              },
              [`&.MuiChip-outlined${capitalize(color)}`]: {
                backgroundColor: defaultTheme.palette[color].light,
                color: defaultTheme.palette[color].dark,
                borderColor: defaultTheme.palette[color].main,

                '&.no-label .MuiChip-label': {
                  paddingLeft: defaultTheme.spacing(0.5),
                  paddingRight: defaultTheme.spacing(0.5),
                },

                [`& .MuiChip-avatarColor${capitalize(color)}`]: {
                  color: 'inherit',
                  backgroundColor: 'transparent !important',
                },

                '& .MuiChip-deleteIcon': {
                  color: defaultTheme.palette[color].dark,
                  fontSize: 20,
                  '&:focus, &:active': {
                    color: defaultTheme.palette[color].dark,
                  },
                },
              },
            }),
            {}
          ),
          '&.MuiChip-filledDefault, &.MuiChip-outlinedDefault': {
            '& .MuiChip-deleteIcon': {
              color: defaultTheme.palette.grey[800],
              '&:focus, &:active': {
                color: defaultTheme.palette.grey[800],
              },
            },
            '&.no-label .MuiChip-label': {
              paddingLeft: defaultTheme.spacing(0.5),
              paddingRight: defaultTheme.spacing(0.5),
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          transition: 'none',
          fontSize: 15,
          minHeight: 40,
          '&:hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: defaultTheme.palette.grey[600],
            },
          },
          '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: primaryMainColor,
            },
          },
        },
        input: {
          padding: '9px 12px',
        },
        notchedOutline: {
          borderColor: defaultTheme.palette.grey[400],
        },
      },
    },
    MuiTooltip: {
      defaultProps: { TransitionComponent: Fade, enterDelay: 50 },
      styleOverrides: {
        tooltip: {
          backgroundColor: defaultTheme.palette.common.white,
          color: defaultTheme.palette.primary.main,
          boxShadow: defaultTheme.shadows[4],
          padding: defaultTheme.spacing(1.5, 2.5),
          ...fontsStyles.subtitle1,
          maxWidth: '276px',
        },
        arrow: {
          '&::before': {
            backgroundColor: defaultTheme.palette.common.white,
            boxShadow: defaultTheme.shadows[2],
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: primaryMainColor,
          '&:hover': {
            backgroundColor: primary.A100,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        groupLabel: {
          marginTop: '16px',
          marginBottom: '8px',
          color: primaryMainColor,
          ...fontsStyles.body1,
          fontWeight: 600,
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          marginTop: 3,
          height: 50,
          borderLeft: `3px dashed ${defaultTheme.palette.grey[300]} !important`,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          paddingLeft: '1px',
          fontSize: '15px',
          fontWeight: 600,
          lineHeight: 1.5,
          '.Mui-completed': {
            color: `${success[600]} !important`,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          minWidth: '340px',
          boxShadow: '8px 0 20px 0',
          color: defaultTheme.palette.common.black,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          '&.modality-badge': {
            '& .MuiBadge-badge.MuiBadge-dot': {
              border: `2px solid white`,
              height: '12px',
              width: '12px',
              borderRadius: '50%',
              top: '2px',
            },
            '&.default': {
              '.MuiBadge-badge.MuiBadge-dot': {
                backgroundColor: grey[400],
              },
            },
            '& .MuiBadge-standard': {
              backgroundColor: 'transparent',
              top: '2px',
            },
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: primary[50],
        },
      },
    },
  },
})

export default muiTheme
