import { capitalize } from 'lodash'
import { useMemo } from 'react'
import { pickupOrDeliveryStatusColors } from 'src/components/Overview/Search/constants'
import EditableSelect from 'src/components/Overview/Table/EditableCells/Select'
import OverviewTableShipmentColoredCell from './OverviewTableShipmentColoredCell'
import { getCellIsEditable } from './EditableCells/utils'

const OverviewTablePickupOrDeliveryStatusCell = ({
  row,
  cell,
  field,
  service,
}) => {
  const isEditable = useMemo(() => getCellIsEditable(cell), [cell])

  const statusValue = row.original?.[field] ?? null

  const statusColor = pickupOrDeliveryStatusColors?.[statusValue] ?? 'primary'

  if (isEditable) {
    return (
      <OverviewTableShipmentColoredCell
        color={statusColor}
        text={capitalize(statusValue)}
        isEditable
      >
        <EditableSelect
          value={statusValue}
          containerId={row.original.id}
          additionalParams={{ service }}
        />
      </OverviewTableShipmentColoredCell>
    )
  }
  return (
    <OverviewTableShipmentColoredCell
      color={statusColor}
      text={capitalize(statusValue)}
    />
  )
}

export default OverviewTablePickupOrDeliveryStatusCell
