import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import DatePicker from 'src/stories/DatePicker'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import FormLabel from 'src/stories/Lab/FormLabel'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import primary from 'src/theme/colors/light/primary'
import grey from 'src/theme/colors/grey'
import { useTranslation } from 'react-i18next'

const DEFAULT_PLACEHOLDER = 'DD-MM-YYYY'
const FROM_FORMAT = 'dd-MM-yyyy'
const TO_FORMAT = 'yyyy-MM-dd'

interface CustomPickerDayProps extends PickersDayProps<DateTime> {
  isLastDay: boolean
}

const queue = (arr, element) => {
  const copy = [...arr]
  copy.unshift(element)
  copy.pop()
  return copy
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, isLastDay, ...props }) => {
  return {
    '&:hover, &:focus': {
      backgroundColor: primary['900'],
      color: theme.palette.common.white,
      borderRadius: '50%',
    },
    ...(isLastDay && {
      backgroundColor: primary['900'],
      borderRadius: '50%',
      color: theme.palette.common.white,
    }),
  }
}) as React.ComponentType<CustomPickerDayProps>

const isDateRangeChanged = (
  prev: Record<'start' | 'end', string | null>,
  next: Record<'start' | 'end', string | null>
) => {
  return prev.start !== next.start || prev.end !== next.end
}

const isDateRangeValid = (start: DateTime | null, end: DateTime | null) => {
  if (!start || !end) return false
  if (!start.isValid || !end.isValid) return false
  return true
}

const isDateRangeValidForSubmit = (
  start: DateTime | null,
  end: DateTime | null
) => {
  if (start === null && end === null) return true
  return isDateRangeValid(start, end)
}

interface Props {
  label?: string
  name: string[]
  placeholder?: string
  qs: Record<string, any>
  inputFormat?: string
  disablePast?: boolean
  disableFuture?: boolean
  clearable?: boolean
  disabled?: boolean
  minDate?: DateTime
  maxDate?: DateTime
  onChange: (value: Record<string, string | null>) => void
}

export const FormDatePicker = ({
  qs,
  name,
  label,
  placeholder = DEFAULT_PLACEHOLDER,
  onChange,
  inputFormat = FROM_FORMAT,
  clearable = true,
  disabled = false,
  ...props
}: Props) => {
  const { t } = useTranslation()
  const startName: string = name[0]
  const endName: string = name[1]
  const [hoveredDay, setHoveredDay] = useState<DateTime | null>(null)
  const [dateRange, setDateRange] = useState<Record<string, DateTime | null>>({
    [startName]: qs[startName] ? DateTime.fromISO(qs[startName]) : null,
    [endName]: qs[endName] ? DateTime.fromISO(qs[endName]) : null,
  })

  useEffect(() => {
    if (
      isDateRangeChanged(
        { start: qs[startName], end: qs[endName] },
        {
          start: dateRange[startName]?.toFormat(TO_FORMAT) || null,
          end: dateRange[endName]?.toFormat(TO_FORMAT) || null,
        }
      )
    ) {
      setDateRange({
        [startName]: qs[startName] ? DateTime.fromISO(qs[startName]) : null,
        [endName]: qs[endName] ? DateTime.fromISO(qs[endName]) : null,
      })
    }
  }, [qs[startName], qs[endName]])

  const onSubmit = (range) => {
    if (isDateRangeValidForSubmit(range[startName], range[endName])) {
      onChange({
        [startName]: range[startName]?.toFormat(TO_FORMAT) || null,
        [endName]: range[endName]?.toFormat(TO_FORMAT) || null,
      })
    }
  }

  const onRangeSelect = (
    name: string,
    date: DateTime | null,
    fromKeyboard: boolean
  ) => {
    if (fromKeyboard) {
      return onInputChange(name, date)
    }
    const values = Object.values(dateRange)
    if (values.filter(Boolean).length === 2) {
      if (name === startName) {
        setDateRange({ [startName]: date, [endName]: null })
      } else {
        setDateRange({ [endName]: date, [startName]: null })
      }
    } else {
      const newRange = queue(values, date).sort(
        (a, b) => a?.toJSDate() - b?.toJSDate()
      )
      const newRangeObject = {
        [startName]: newRange[0],
        [endName]: newRange[1],
      }
      setDateRange(newRangeObject)
      onSubmit(newRangeObject)
    }
  }

  const onInputChange = (name: string, date: DateTime | null) => {
    const newRangeObject = { ...dateRange, [name]: date }
    setDateRange(newRangeObject)
    onSubmit(newRangeObject)
  }

  const renderWeekPickerDay = (
    name: string,
    dayProps: PickersDayProps<DateTime>
  ) => {
    if (!dateRange[name]) {
      return (
        <PickersDay
          {...dayProps}
          onClick={() => {
            onRangeSelect(name, dayProps.day, false)
          }}
        />
      )
    }
    const values: any = Object.values(dateRange)
    const currentDateTime = dayProps.day.startOf('day').toMillis()
    const firstDateOfRange = values[0]?.startOf('day').toMillis()
    const lastDateOfRange = values[1]
      ? values[1]?.startOf('day').toMillis()
      : hoveredDay?.startOf('day').toMillis()
    const isFirstDay = currentDateTime === firstDateOfRange
    const isLastDay = currentDateTime === lastDateOfRange
    const dayIsBetween =
      (currentDateTime <= lastDateOfRange &&
        currentDateTime >= firstDateOfRange) ||
      (currentDateTime <= firstDateOfRange &&
        currentDateTime >= lastDateOfRange)
    const isCurrentMonth = !dayProps.outsideCurrentMonth

    const styles = () => {
      let style = {}

      if (!isCurrentMonth) return style

      if (dayIsBetween) {
        style = {
          ...style,
          backgroundColor: primary['50'],
        }
      }

      if (isFirstDay || isLastDay) {
        if (isFirstDay) {
          if (lastDateOfRange < firstDateOfRange) {
            style = {
              ...style,
              borderTopRightRadius: '50%',
              borderBottomRightRadius: '50%',
            }
          }
          if (lastDateOfRange > firstDateOfRange) {
            style = {
              ...style,
              borderTopLeftRadius: '50%',
              borderBottomLeftRadius: '50%',
            }
          }
          if (lastDateOfRange === firstDateOfRange) {
            style = {
              ...style,
              borderRadius: '50%',
            }
          }
        }

        if (isLastDay) {
          if (lastDateOfRange > firstDateOfRange) {
            style = {
              ...style,
              borderTopRightRadius: '50%',
              borderBottomRightRadius: '50%',
            }
          }
          if (lastDateOfRange < firstDateOfRange) {
            style = {
              ...style,
              borderTopLeftRadius: '50%',
              borderBottomLeftRadius: '50%',
            }
          }
        }
      }

      return style
    }

    return (
      <Box key={dayProps.key} sx={styles}>
        <CustomPickersDay
          {...dayProps}
          onMouseOver={() => setHoveredDay(dayProps.day)}
          onDaySelect={() => onRangeSelect(name, dayProps.day, false)}
          isLastDay={isLastDay}
        />
      </Box>
    )
  }

  const customeLocaleText = {
    calendarWeekNumberText: (weekNumber) =>
      (
        <Box
          onClick={() => {
            const date = DateTime.fromObject({
              weekNumber: weekNumber,
            })
            const newRange = {
              [startName]: date.startOf('week'),
              [endName]: date.endOf('week'),
            }
            setDateRange(newRange)
            onSubmit(newRange)
          }}
          sx={{ cursor: 'pointer' }}
          title={t('calendar_component.week_number_title', {
            number: weekNumber,
          })}
        >
          {weekNumber}
        </Box>
      ) as any,
  }

  return (
    <Box>
      {label && <FormLabel label={label} />}
      <Box
        sx={{
          display: 'flex',
          borderWidth: '1px',
          borderColor: grey['400'],
          borderStyle: 'solid',
          boxSizing: 'border-box',
          height: '40px',
          borderRadius: '5px',
          alignItems: 'center',
          position: 'relative',
          padding: '9px 12px',
          '& .MuiInputBase-input': {
            padding: 0,
          },
          '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
        }}
      >
        <DatePicker
          onChange={(day, fromKeyboard) => {
            onRangeSelect(startName, day, fromKeyboard)
          }}
          value={dateRange[startName]}
          inputFormat={inputFormat}
          placeholder={placeholder}
          minDate={props.minDate}
          maxDate={props.maxDate}
          closeOnSelect={false}
          disabled={disabled}
          data-testid={startName}
          calendarSlots={{
            day: (dayProps) => renderWeekPickerDay(startName, dayProps),
          }}
          clearable={false}
          localeText={customeLocaleText}
        />
        <Box
          sx={{
            display: 'flex',
            color: grey['200'],
            padding: '0 8px',
            justifyContent: 'center',
          }}
        >
          |
        </Box>
        <DatePicker
          onChange={(day, fromKeyboard) =>
            onRangeSelect(endName, day, fromKeyboard)
          }
          value={dateRange[endName]}
          inputFormat={inputFormat}
          placeholder={placeholder}
          minDate={props.minDate}
          maxDate={props.maxDate}
          closeOnSelect={false}
          disabled={disabled}
          data-testid={endName}
          calendarSlots={{
            day: (dayProps) => renderWeekPickerDay(endName, dayProps),
          }}
          clearable={false}
          localeText={customeLocaleText}
        />
        {clearable &&
          isDateRangeValid(dateRange[startName], dateRange[endName]) && (
            <IconButton
              onClick={() => {
                setDateRange({
                  [startName]: null,
                  [endName]: null,
                })
                onChange({
                  [startName]: null,
                  [endName]: null,
                })
              }}
              size="small"
              sx={{ position: 'absolute', right: 0 }}
              data-testid="datepicker-clear-button"
            >
              <CloseIcon />
            </IconButton>
          )}
      </Box>
    </Box>
  )
}
