import { styled, TextareaAutosize as BaseTextareaAutosize } from '@mui/material'
import TextField from '@mui/material/TextField'
import InputBase from '@mui/material/InputBase'

export const TextareaAutosize = styled(BaseTextareaAutosize)(({ theme }) => {
  return {
    border: 'none',
    outline: 'none',
    lineHeight: '1.5',
    padding: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    resize: 'none',
    width: '100%',
    backgroundColor: 'transparent',
  }
})

export const SelectInput = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
      borderRadius: 0,
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-disabled ': {
      '& fieldset': {
        borderColor: 'transparent',
        borderRadius: 0,
      },
    },
  },
  '&.Mui-focused fieldset': {
    borderColor: 'transparent',
  },
})

export const SelectInputBase = styled(InputBase)({
  '& .MuiInputBase-input': {
    border: 'none',
  },
})
