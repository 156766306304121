import { useMemo } from 'react'
import { useGetAddressesAsync } from 'src/services/Api/common'
import AsyncSelect from './AsyncSelect'
import { getCellIsEditable, getFormattedAddressData } from './utils'

const EditableAddress = ({ cell, row, service }) => {
  const isEditable = useMemo(() => getCellIsEditable(cell), [cell])

  return (
    <AsyncSelect
      field="address_id"
      value={row.original?.[`${service}_address_id`] ?? ''}
      label={row.original?.[`${service}_address_name`] ?? ''}
      isEditable={isEditable}
      containerId={row.original.id}
      additionalParams={{ service }}
      formatter={getFormattedAddressData}
      fetchFunction={useGetAddressesAsync}
      fetchParams={{
        page: 1,
        per_page: 10,
        organization_id: row.original.shipment_owner_organization_id,
      }}
    />
  )
}

export default EditableAddress
