import { useUpdateContainer } from 'src/services/Api/overview'
import { MRT_RowData, MRT_Cell } from 'material-react-table'
import { customFields, tableCellMutationKey } from '../../constants'
import { TextFieldCell, TextFieldCellProps } from './TextField'

interface ContainerTextProps<TData extends MRT_RowData>
  extends TextFieldCellProps<TData> {
  containerId: number
  requestParam: string
}

const ContainerText = <TData extends MRT_RowData>(
  props: ContainerTextProps<TData>
) => {
  const { mutateAsync: updateInlandContainer, isLoading } = useUpdateContainer({
    retry: 0,
  })

  const { containerId, requestParam, cell, ...otherProps } = props

  const onClose = (value: string | null) => {
    updateInlandContainer({
      mutationKey: [tableCellMutationKey],
      params: {
        custom_fields: customFields,
        containers: [
          {
            id: containerId,
            [requestParam]: value,
          },
        ],
      },
    })
  }

  return (
    <TextFieldCell
      {...otherProps}
      onClose={onClose}
      isLoading={isLoading}
      cell={cell as MRT_Cell<MRT_RowData>}
    />
  )
}

export default ContainerText
