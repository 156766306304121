import { useState, useCallback, useEffect } from 'react'
import { uniqBy, debounce } from 'lodash'
import {
  useCreateRecentSearch,
  useGetCarriersAsync,
} from 'src/services/Api/recentSearches'
import { FilterOptionValue } from 'src/components/Common/AutocompleteWrappers/types'
import {
  RecentSearches,
  RecentSearchesResponse,
} from 'src/@types/endpoints/common'
import { queryClient } from 'src/services/http-common'
import { queryKeys } from 'src/services/endpoints'
import { useTranslation } from 'react-i18next'

interface BaseAutocompleteWrapperProps {
  name?: 'pol' | 'pod'
  names?: ('pol' | 'pod')[]
  params: Record<string, unknown>
  formatter: (data: any) => FilterOptionValue[]
  defaultValue: FilterOptionValue[]
  fetchFunction: () => {
    fetchAsync: (params: any) => Promise<unknown>
    isFetched: boolean
    isFetching: boolean
  }
  recentSearchesFormatter?: (
    data: RecentSearchesResponse,
    name: string,
    label: string
  ) => FilterOptionValue[]
  WrappedAutocompleteComponent: React.ComponentType<any>
}

const BaseAutocompleteWrapper = ({
  name,
  names,
  params,
  formatter,
  defaultValue,
  fetchFunction,
  recentSearchesFormatter,
  WrappedAutocompleteComponent,
}: BaseAutocompleteWrapperProps) => {
  const useRecentSearches = !!recentSearchesFormatter && !!name && !!names
  const { t } = useTranslation()

  const [recentSearchOptions, setRecentSearchOptions] = useState<
    FilterOptionValue[]
  >([])

  const { fetchAsync, isFetched, isFetching } = fetchFunction()

  const { fetchAsync: getRecentSearches } = useGetCarriersAsync({
    retry: 0,
    onSuccess: (data) => {
      if (!useRecentSearches) return
      setRecentSearchOptions(
        recentSearchesFormatter(
          data,
          name,
          t('components.autocomplete.recent_options', 'Recent searches')
        )
      )
    },
  })

  const { mutateAsync: createRecentSearch } = useCreateRecentSearch()

  const [defaultOptions, setDefaultOptions] = useState<FilterOptionValue[]>(
    defaultValue
  )

  const [inputValue, setInputValue] = useState('')

  const options: FilterOptionValue[] = uniqBy(
    [...recentSearchOptions, ...defaultOptions],
    'value'
  )

  const handleDebounceFn = async (input: string) => {
    setInputValue(input)
    const hasOption = defaultValue.some((option) => option.label === input)
    if (input.length === 0 || hasOption) {
      return
    }

    const data = await fetchAsync({
      input,
      ...params,
    })

    const newlyFetchedOptions: FilterOptionValue[] = formatter(data)

    setDefaultOptions(uniqBy([...options, ...newlyFetchedOptions], 'value'))
  }

  const handleInputChangeWithDebounce = useCallback(
    debounce(handleDebounceFn, 800),
    [defaultValue]
  )

  const handleOnInputChange = (search: string) => {
    handleInputChangeWithDebounce(search)
  }

  const addRecentSearch = (values) => {
    if (!useRecentSearches) return

    createRecentSearch({
      name,
      values: values.splice(0, 5),
    }).then((response) => {
      queryClient.setQueriesData(
        [queryKeys.commonRecentSearches],
        (oldParams: any) => {
          if (!oldParams) return oldParams

          const newRecentSearches = oldParams.data.map(
            (item: RecentSearches) => {
              if (item.name !== name) return item

              return response.data
            }
          )
          return { data: newRecentSearches }
        }
      )
    })
  }

  const handleSelectChange = (selected: FilterOptionValue[]) => {
    if (!useRecentSearches) return

    const values = selected
      ? selected.reduce((acc: number[], item: FilterOptionValue): number[] => {
          const existingRecentSearchIds = recentSearchOptions.map(
            (recentSearch: FilterOptionValue) => recentSearch.value
          )
          if (!existingRecentSearchIds.includes(item.value)) {
            acc.push(Number(item.value))
          }
          return acc
        }, [])
      : []

    if (values.length > 0) {
      addRecentSearch(values)
    }
  }

  const noOptionsText =
    isFetched && inputValue.length > 0 ? 'No options' : 'Start typing...'

  useEffect(() => {
    if (useRecentSearches) {
      getRecentSearches({ names })
    }
  }, [])

  return (
    <WrappedAutocompleteComponent
      options={options}
      noOptionsText={noOptionsText}
      onInputChange={handleOnInputChange}
      onChange={handleSelectChange}
      loading={isFetching}
      inputValue={inputValue}
    />
  )
}

export default BaseAutocompleteWrapper
