import { ChipProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import { StyledFilledBox } from '../styled'

const OverviewTableShipmentColoredCell = ({
  color,
  text,
  children = null,
  isEditable = false,
}: {
  color: ChipProps['color']
  text: string
  children?: React.ReactNode
  isEditable?: boolean
}) => {
  return (
    <StyledFilledBox
      className={`filled-cell ${color}${isEditable ? ' is-editable' : ''}`}
      sx={{
        color: `${color}.900`,
        backgroundColor: `${color}.50`,
      }}
      data-testid="filled-cell"
    >
      {children}
      {!children && <Typography variant="body1" noWrap children={text} />}
    </StyledFilledBox>
  )
}

export default OverviewTableShipmentColoredCell
