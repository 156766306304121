import { FunctionComponent, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { find, compact, startCase, capitalize } from 'lodash'
import Grid from '@mui/material/Grid2'
import { Stack, Box, Button, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import Chip from '@mui/material/Chip'
import { ModalityEnum } from 'src/config/constants'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { Counter } from '../../../stories/Counter'
import { containerTypeDescription } from '../../../utils/helpers'
import { acceptInlandTransportStatus } from '../../../stores/actionCreators'
import { permissionTo, promisifyAction } from '../../../utils/'
import ContainerOverviewHeaderCollapsedActions from './ContainerOverviewHeaderCollapsedActions'

interface IProps {
  shipmentData: IShipmentMainData
  containerData: IShipmentContainer
  isLclOrAir: boolean
  isSailingTBA: boolean
  collapsed?: boolean
  openDateTimeModal: (transport: IInlandTransport, containerId: number) => void
  openAddressModal: (
    address: IInlandTransportAddress | null,
    service: InlandTransportService,
    containerId: number
  ) => void
  openContainerWindow: (id: number) => void
  openDuplicateContainerWindow: (id: number) => void
  confirmDeletion: (id: number) => void
  fetchData: () => void
  openCargoDetails: (id: number) => void
  isSingleContainer: boolean
  shipmentModality: ModalityEnum
}

const ContainerOverviewHeaderCollapsed: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { cargo, inland_transports } = props.containerData
  const { shipment_phase } = props.shipmentData

  const containerStatus = props.containerData.status

  const acceptInlandTransportStatusAsync = promisifyAction(
    dispatch,
    acceptInlandTransportStatus
  )

  const service: InlandTransportService =
    shipment_phase === 'origin' ? 'pickup' : 'delivery'

  const isOriginPhase: boolean = shipment_phase === 'origin'

  const viewPermission: boolean = permissionTo(
    `shipments.containers_|_cargo.${service}.view`
  )
  const managePermission: boolean = permissionTo(
    `shipments.containers_|_cargo.${service}.manage`
  )
  const containerManagePermission: boolean = permissionTo(
    `shipments.containers.manage`
  )
  const cargoLinesView: boolean = permissionTo(
    'shipments.containers_|_cargo.cargo_lines.view'
  )
  const cargoLinesManage: boolean = permissionTo(
    'shipments.containers_|_cargo.cargo_lines.manage'
  )

  const pickupTransport = find(inland_transports, {
    service: 'pickup',
  })
  const deliveryTransport = find(inland_transports, {
    service: 'delivery',
  })

  const transport = (isOriginPhase
    ? pickupTransport
    : deliveryTransport) as IInlandTransport

  const transportAddress = transport?.inland_transport_addresses?.length
    ? transport.inland_transport_addresses[0]
    : null

  const cargoDescription = (): string => {
    if (cargo && cargo.length) {
      const descriptionsArr: string[] = cargo.map((item) => {
        return item.goods_description || '-'
      })
      const goodsDescription: string =
        cargo.length > 1
          ? compact(descriptionsArr).join(', ')
          : descriptionsArr[0]
      if (goodsDescription.length > 50) {
        return goodsDescription.slice(0, 50) + '...'
      }
      return goodsDescription
    }
    return '-'
  }

  const acceptDateAndTime = async (): Promise<any> => {
    if (transportAddress?.address_id) {
      await acceptInlandTransportStatusAsync(props.containerData.id, {
        service_type: service,
      })
      props.fetchData()
    } else {
      requestAddress()
      showErrorMessage()
    }
  }

  const requestTime = (e): void => {
    e.stopPropagation()
    if (transportAddress?.address_id) {
      props.openDateTimeModal(transport, props.containerData.id)
    } else {
      requestAddress()
      showErrorMessage()
    }
  }

  const requestAddress = useCallback(() => {
    props.openAddressModal(null, service, props.containerData.id)
  }, [props.containerData.id, service])

  const openContainerChange = useCallback(() => {
    props.openContainerWindow(props.containerData.id)
  }, [props.containerData.id, props.containerData.id])

  const openCargoDetails = (e): void => {
    if (!props.collapsed) {
      e.stopPropagation()
    }
    props.openCargoDetails(props.containerData.id)
  }

  const isEstimated: boolean = transport?.status === 'estimated'

  const dateTimeRange = t('common.date_time_range_med', {
    defaultValue: '{{value, DATETIME_RANGE_MED}}',
    value: [transport?.pickup_delivery_time, transport?.closing_time],
  })

  const dateTimeBlock: React.ReactNode = (
    <Box textAlign="left" mb={1}>
      {transport?.pickup_delivery_time_editable && managePermission ? (
        <Link
          sx={{ textAlign: 'left' }}
          variant="body1"
          component="button"
          onClick={requestTime}
        >
          {dateTimeRange}
        </Link>
      ) : (
        <div>{dateTimeRange}</div>
      )}
    </Box>
  )

  const addCargoBlock: React.ReactNode = (
    <>
      <div>
        {cargoLinesManage ? (
          <Link
            component="button"
            variant="body1"
            onClick={(e) => openCargoDetails(e)}
          >
            {t('common.buttons.add', 'Add')}
          </Link>
        ) : (
          '-'
        )}
      </div>
    </>
  )

  const showErrorMessage = () => {
    dispatch(
      showNotification({
        message: t('shipment_containers.notifications.enter_service_address', {
          defaultValue: 'Please enter your {{service}} address first.',
          service,
        }),
        severity: 'error',
      })
    )
  }

  const openTasksCount = useMemo(() => {
    return props.containerData.milestones.filter(
      (x) => !x.reached && x.status !== 'upcoming'
    ).length
  }, [props.containerData.milestones])

  return (
    <Box
      sx={{
        backgroundColor: !props.collapsed ? 'grey.100' : 'white',
        border: 'grey.100',
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid size={6}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              variant="body1Strong"
              data-testid="shipment-container-number-value"
            >
              {props.containerData?.number ?? (
                <>
                  {containerManagePermission && (
                    <Link
                      variant="body1"
                      component="button"
                      onClick={openContainerChange}
                      data-testid="shipment-container-number-add"
                    >
                      {t(
                        'shipment_containers.add_container_number',
                        'Add container number'
                      )}
                    </Link>
                  )}
                </>
              )}
              {props.containerData.shared_reference &&
                ` | ${props.containerData.shared_reference}`}
            </Typography>
            {containerStatus && (
              <Chip
                label={capitalize(
                  startCase(containerStatus.replace(/_/g, ' '))
                )}
                size="small"
                color="secondary"
              />
            )}
          </Stack>
        </Grid>
        <Grid size={6} display="flex" justifyContent="end">
          {containerManagePermission &&
            props.shipmentModality !== ModalityEnum.Air && (
              <ContainerOverviewHeaderCollapsedActions
                containerData={props.containerData}
                openContainerWindow={props.openContainerWindow}
                collapsed={props.collapsed}
                shipmentData={props.shipmentData}
                openDuplicateContainerWindow={
                  props.openDuplicateContainerWindow
                }
                confirmDeletion={props.confirmDeletion}
                isSingleContainer={props.isSingleContainer}
              />
            )}
        </Grid>
        <Grid size="grow">
          <Typography
            variant="body1Strong"
            children={t(
              'common.forms.fields.container_type.label',
              'Container type'
            )}
          />
          <Stack direction="row" alignItems="center">
            {props.containerData.container_event_exceptions.length > 0 && (
              <i className="icon attention" />
            )}
            {!props.isLclOrAir && (
              <Typography
                variant="body1"
                data-testid="shipment-container-type-value"
              >
                {containerTypeDescription(
                  props.shipmentData.type,
                  props.containerData.container_type.name || null
                )}
              </Typography>
            )}
          </Stack>
        </Grid>
        {cargoLinesView && (
          <Grid size="grow">
            <Typography
              variant="body1Strong"
              children={t('shipment_containers.cargo', 'Cargo')}
            />
            {cargo?.length > 0 && (
              <Typography
                noWrap
                variant="body1"
                children={cargoDescription()}
              />
            )}
            {cargo?.length === 0 && addCargoBlock}
          </Grid>
        )}
        {viewPermission && (
          <Grid size="grow">
            <Typography
              variant="body1Strong"
              children={`${
                isOriginPhase
                  ? t(
                      'shipment_containers.pickup_date_and_time',
                      'Pick-up date & time'
                    )
                  : t(
                      'shipment_containers.delivery_date_and_time',
                      'Delivery date & time'
                    )
              }`}
            />
            {transportAddress && props.isSailingTBA && (
              <Typography
                variant="body1Strong"
                children={t(
                  'shipment_containers.to_be_announced',
                  'To be announced'
                )}
              />
            )}
            {transportAddress && !props.isSailingTBA && (
              <>
                {isEstimated &&
                  transport?.pickup_delivery_time_editable &&
                  managePermission && (
                    <Box>
                      <Link
                        variant="body1"
                        component="button"
                        onClick={requestTime}
                        children={t('common.buttons.request', 'Request')}
                      />
                    </Box>
                  )}
                {isEstimated &&
                  transport?.pickup_delivery_time &&
                  !transport?.pickup_delivery_time_editable && (
                    <Typography
                      variant="body1"
                      children={t('shipment_containers.estimated_on', {
                        defaultValue: 'Estimated on {{date, DATE_MED}}',
                        date: transport.pickup_delivery_time,
                      })}
                    />
                  )}
                {transport?.status === 'proposed' && managePermission && (
                  <>
                    {dateTimeBlock}
                    <Stack direction="row" spacing={1}>
                      <Button
                        startIcon={<CheckRoundedIcon />}
                        variant="outlined"
                        children={t('common.buttons.accept', 'Accept')}
                        onClick={acceptDateAndTime}
                      />
                      <Button variant="contained" onClick={requestTime}>
                        {t('common.buttons.change', 'Change')}
                      </Button>
                    </Stack>
                  </>
                )}
                {transport?.status === 'requested' && (
                  <>
                    {dateTimeBlock}
                    <Typography
                      variant="body1"
                      children={t(
                        'shipment_containers.to_be_confirmed_by_forwarder',
                        'To be confirmed by the forwarder'
                      )}
                    />
                  </>
                )}
                {transport?.status === 'confirmed' && (
                  <Typography
                    variant="body1"
                    children={t(
                      'shipment_containers.transport_confirmed',
                      'Confirmed'
                    )}
                  />
                )}
              </>
            )}
          </Grid>
        )}
        <Grid size="grow">
          <Typography
            variant="body1Strong"
            children={t('shipment_containers.tasks', 'Tasks')}
          />
          {openTasksCount > 0 && (
            <Counter value={openTasksCount} className={'red'} />
          )}
          {openTasksCount === 0 && (
            <Typography
              variant="body1"
              children={t('shipment_containers.none', 'None')}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContainerOverviewHeaderCollapsed
