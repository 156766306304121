import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { apiClient } from 'src/services/http-common'
import { endpoints, queryKeys } from 'src/services/endpoints'
import { useQueryAsync } from '../../hooks'
import { createQueryString } from '../../utils'
import {
  OverviewResponse,
  OverviewRequest,
  OverviewTaskTypeResponse,
  OverviewUpdateShipmentRequest,
  OverviewAllowedFiltersAndColumnsRequest,
  OverviewAllowedColumnsAndFiltersResponse,
  OverviewUpdateInlandTransportRequest,
  OverviewUpdateContainerRequest,
  OverviewUpdateTaskRequest,
} from './types'

export const useGetOverview = (
  payload: OverviewRequest,
  options?: UseQueryOptions<OverviewResponse, AxiosError, OverviewResponse>
) => {
  const queryString = createQueryString(payload.params, {
    skipEmptyString: false,
  })

  const url = endpoints.containersOverview + queryString
  const { containersOverview: key } = queryKeys

  const getOverview = () =>
    apiClient.get<OverviewRequest, OverviewResponse>(url)

  return useQuery<OverviewResponse, AxiosError>(
    [key, ...payload.keys],
    getOverview,
    options
  )
}

export const useGetTaskTypes = (
  options?: UseQueryOptions<
    OverviewTaskTypeResponse,
    AxiosError,
    OverviewTaskTypeResponse
  >
) => {
  const endpoint = endpoints.taskTypes
  const { taskTypes: key } = queryKeys

  const getTaskTypes = () =>
    apiClient.get<any, OverviewTaskTypeResponse>(endpoint)

  return useQuery<OverviewTaskTypeResponse, AxiosError>(
    [key],
    getTaskTypes,
    options
  )
}

export const useGetOverviewVesselContainersAsync = (
  options?: UseQueryOptions<OverviewResponse, AxiosError, OverviewResponse>
) => {
  const { vesselsOverview: key } = queryKeys

  const getVesselContainers = (payload) => {
    const { id, ...requestPayload } = payload
    const queryString = createQueryString(requestPayload)
    const url = `${endpoints.vesselsOverview}/${id}/containers${queryString}`
    return apiClient.get<any, OverviewResponse>(url)
  }

  return useQueryAsync([key], getVesselContainers, options)
}

export const useGetAllowedFiltersAndColumns = (
  payload: OverviewAllowedFiltersAndColumnsRequest,
  options?: UseQueryOptions<
    OverviewAllowedColumnsAndFiltersResponse,
    AxiosError,
    OverviewAllowedColumnsAndFiltersResponse
  >
) => {
  const queryString = createQueryString(payload.params, {
    skipEmptyString: false,
  })
  const url = endpoints.overviewAllowedFiltersAndColumns + queryString
  const { overviewAllowedFiltersAndColumns: key } = queryKeys

  const getAllowedFiltersAndColumns = () =>
    apiClient.get<any, OverviewAllowedColumnsAndFiltersResponse>(url)

  return useQuery<OverviewAllowedColumnsAndFiltersResponse, AxiosError>(
    [key],
    getAllowedFiltersAndColumns,
    options
  )
}

export const useUpdateInlandTransport = (
  options?: UseMutationOptions<
    OverviewResponse,
    AxiosError,
    OverviewUpdateInlandTransportRequest
  >
) => {
  const updateInlandTransport = (
    payload: OverviewUpdateInlandTransportRequest
  ) => {
    const url = endpoints.inlandTransportsOverview

    return apiClient.put<any, OverviewResponse>(
      url,
      JSON.stringify(payload.params)
    )
  }

  return useMutation({
    mutationFn: updateInlandTransport,
    ...options,
  })
}

export const useUpdateShipment = (
  options?: UseMutationOptions<
    OverviewResponse,
    AxiosError,
    OverviewUpdateShipmentRequest
  >
) => {
  const updateShipment = (payload: OverviewUpdateShipmentRequest) => {
    const url = endpoints.shipmentsOverview

    return apiClient.put<any, OverviewResponse>(
      url,
      JSON.stringify(payload.params)
    )
  }

  return useMutation({
    mutationFn: updateShipment,
    ...options,
  })
}

export const useUpdateContainer = (
  options?: UseMutationOptions<
    OverviewResponse,
    AxiosError,
    OverviewUpdateContainerRequest
  >
) => {
  const updateContainer = (payload: OverviewUpdateContainerRequest) => {
    const url = endpoints.containersOverview

    return apiClient.put<any, OverviewResponse>(
      url,
      JSON.stringify(payload.params)
    )
  }

  return useMutation({
    mutationFn: updateContainer,
    ...options,
  })
}

export const useUpdateTask = (
  options?: UseMutationOptions<
    OverviewResponse,
    AxiosError,
    OverviewUpdateTaskRequest
  >
) => {
  const updateTask = (payload: OverviewUpdateTaskRequest) => {
    const url = endpoints.tasks

    return apiClient.put<any, OverviewResponse>(
      url,
      JSON.stringify(payload.params)
    )
  }

  return useMutation({
    mutationFn: updateTask,
    ...options,
  })
}
