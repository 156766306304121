import {
  useQuery,
  UseQueryOptions,
  UseMutationOptions,
  useMutation,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  RecentSearchesResponse,
  RecentSearchesRequest,
  RecentSearchesParams,
} from 'src/@types/endpoints/common'
import endpoints, { queryKeys } from '../endpoints'
import { apiClient } from '../http-common'
import { createQueryString } from '../utils'
import { useQueryAsync } from '../hooks'

export const useGetRecentSearches = (
  payload: RecentSearchesParams,
  options?: UseQueryOptions<
    RecentSearchesResponse,
    AxiosError,
    RecentSearchesResponse
  >
) => {
  const queryString = createQueryString(payload)
  const url = endpoints.commonRecentSearches + queryString
  const { commonRecentSearches: key } = queryKeys

  const getRecentSearches = () =>
    apiClient.get<RecentSearchesParams, RecentSearchesResponse>(url)
  return useQuery<RecentSearchesResponse, AxiosError>(
    [key],
    getRecentSearches,
    options
  )
}

export const useGetCarriersAsync = (
  options?: UseQueryOptions<
    RecentSearchesResponse,
    AxiosError,
    RecentSearchesResponse
  >
) => {
  const { commonRecentSearches: key } = queryKeys

  const getRecentSearches = (payload: RecentSearchesParams) => {
    const queryString = createQueryString(payload)
    const url = endpoints.commonRecentSearches + queryString

    return apiClient.get<any, RecentSearchesResponse>(url)
  }

  return useQueryAsync([key], getRecentSearches, options)
}

export const useCreateRecentSearch = (
  options?: UseMutationOptions<
    RecentSearchesResponse,
    AxiosError,
    RecentSearchesRequest
  >
) => {
  const createRecentSearch = (payload: RecentSearchesRequest) => {
    const url = endpoints.commonRecentSearches

    return apiClient.post<any, RecentSearchesResponse>(
      url,
      JSON.stringify(payload)
    )
  }
  return useMutation(createRecentSearch, options)
}
