import { useMemo } from 'react'
import { useUpdateInlandTransport } from 'src/services/Api/overview'
import OverviewTableText from '../OverviewTableText'
import { customFields, tableCellMutationKey } from '../../constants'
import { getCellIsEditable } from './utils'
import NotesEditableCell from './NotesEditableCell'

const EditableNotes = ({
  cell,
  containerId,
  additionalParams,
  missingValueText,
}) => {
  const cellIsEditable = useMemo(() => {
    return getCellIsEditable(cell)
  }, [cell])

  const { mutateAsync: updateInlandTransport } = useUpdateInlandTransport({
    retry: 0,
  })

  const updateCell = async (notes: string) => {
    if (notes === cell.getValue()) {
      return
    }
    updateInlandTransport({
      mutationKey: [tableCellMutationKey],
      params: {
        custom_fields: customFields,
        inland_transports: [
          {
            container_id: containerId,
            notes: notes.length > 0 ? notes : null,
            ...additionalParams,
          },
        ],
      },
    })
  }

  if (cellIsEditable) {
    return (
      <NotesEditableCell
        cell={cell}
        onPopoverClose={updateCell}
        missingValueText={missingValueText}
      />
    )
  }

  return <OverviewTableText text={cell.getValue()} data-testid="notes-cell" />
}

export default EditableNotes
