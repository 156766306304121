import { capitalize } from 'lodash-es'

export const formatText = (text: string) => capitalize(text.replace(/_/g, ' '))

export const shippingInstructionTitle = (booking: IBooking) => {
  const name = booking.shipper.shipment_address?.name
  if (name) return `${booking.booking_index} ${name}`
  return booking.booking_index
}

export const setProperty = (
  obj: Record<string, any>,
  path: string,
  value: any
) => {
  const [head, ...rest] = path.split('.')

  return {
    ...obj,
    [head]: rest.length ? setProperty(obj[head], rest.join('.'), value) : value,
  }
}
