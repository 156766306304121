import { ReactNode, FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import { promisifyAction } from '../../../../utils'
import { modalDisplayChange } from '../../../../stores/actionCreators'

interface IProps {
  children: ReactNode
  open: boolean
  className?: string
  title?: string
  onClose?: () => void
  maxWidth?: DialogProps['maxWidth']
  fullWidth?: DialogProps['fullWidth']
  onKeyUp?: DialogProps['onKeyUp']
}

const Window: FunctionComponent<IProps> = (props) => {
  const dispatch = useDispatch()
  const openModalAsync = promisifyAction(dispatch, modalDisplayChange)

  const handleClose = (): void => {
    openModalAsync(false)
  }

  const close = (): void => {
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <Dialog
      data-testid="modal-window"
      onKeyUp={props.onKeyUp}
      fullWidth={props.fullWidth}
      open={props.open}
      disableEnforceFocus={true}
      onBackdropClick={handleClose}
      onClose={close}
      maxWidth={props.maxWidth || 'lg'}
    >
      {props.children}
    </Dialog>
  )
}

export default Window
