import { FunctionComponent } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import SelectorInput from '../CustomInputs/SelectorInput'

import './styles.scss'

interface IProps {
  className?: string
  placeholder?: string
  disabled?: boolean
  label?: string
  value?: string | number | null
  name?: string
  theme?: string
  showError?: boolean
  errorMessage?: string | null
  onChange?: (value: any) => void
  options?: any[]
}

const Selector: FunctionComponent<IProps> = (props) => {
  const handleChange = (event: SelectChangeEvent<string | number>): void => {
    if (props.onChange) {
      props.onChange({
        id: event.target.value,
      })
    }
  }

  return (
    <div className={props.theme}>
      <Select
        value={props.value || 0}
        onChange={handleChange}
        name="common-input-selector"
        className={''}
        classes={{
          select: 'common-select__root',
          disabled: 'common-select__disabled',
        }}
        input={<SelectorInput />}
        disabled={props.disabled}
        MenuProps={{
          classes: { paper: 'common-select__paper' },
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        inputProps={{ className: 'common-select__input' }}
      >
        <MenuItem
          value={0}
          classes={{
            root: 'common-select__default-item',
          }}
        >
          Select time
        </MenuItem>
        {(props.options || []).map((item) => {
          return (
            <MenuItem key={item.name} value={item.id}>
              {item.name}
            </MenuItem>
          )
        })}
      </Select>
    </div>
  )
}

Selector.defaultProps = {
  disabled: false,
  showError: false,
  className: '',
  onChange: (e) => undefined,
  options: [],
}

export default Selector
