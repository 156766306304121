import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { isNull } from 'lodash'
import { Dialog, DialogContent } from 'src/stories/Dialogs'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { getVisibilityShipmentCarriers } from 'src/stores/actionCreators'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { history } from 'src/shyppleStore'
import {
  useCreateShipment,
  useGetShipmentsAsync,
  useGetShipmentsMetaAsync,
} from 'src/services/Api/shipments'
import { FormProvider } from 'src/components/Templates/Form/FormContext'
import { formatFiltersToParams } from '../../pages/Shipments/helpers'
import * as actions from '../../stores/actions/shipments'
import { permissionTo } from '../../utils'
import { initialState, initialFormErrors } from './Form.constants'
import { VisibilityFormContextProps } from './Form.props'
import ShipmentInfoFields from './ShipmentInfoFields'
import TrackingInformation from './TrackingInformation'

interface IShipmentVisibilityModalProps {
  open: boolean
  handleModalOpen: (state: boolean) => void
}

const ShipmentVisibilityModal: FunctionComponent<IShipmentVisibilityModalProps> = ({
  open,
  handleModalOpen,
}) => {
  const { t } = useTranslation()
  const [formState, setFormState] = useState({ ...initialState })
  const [formErrors, setFormErrors] = useState({ ...initialFormErrors })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onChange = (newPartiallyUpdatedForm) => {
    setFormState((prevState) => ({
      ...prevState,
      ...newPartiallyUpdatedForm,
    }))
  }

  const onFormErrorChange = (newPartiallyUpdatedForm) => {
    setFormErrors((prevState) => ({
      ...prevState,
      ...newPartiallyUpdatedForm,
    }))
  }

  const dispatch = useDispatch()

  const { user, filters } = useSelector(
    (state: IGlobalState) => ({
      user: state.user,
      filters: state.shipments.filters,
    }),
    shallowEqual
  )

  const { fetchAsync: shipmentsGetData } = useGetShipmentsAsync()
  const { fetchAsync: shipmentsGetMetaData } = useGetShipmentsMetaAsync()
  const { mutateAsync: createVisibilityShipment } = useCreateShipment()

  const customerPermission = permissionTo(
    'search_and_book.organization_select.all'
  )

  useEffect(() => {
    ;(async () => {
      dispatch(
        getVisibilityShipmentCarriers({
          carrier_type: 'carrier',
        })
      )
    })()
  }, [])

  const noTrackingKey: boolean =
    !formState.container_number &&
    !formState.booking_number &&
    !formState.bl_number

  const isDisabled: boolean = useMemo(() => {
    return (
      isNull(formState.carrier) ||
      noTrackingKey ||
      (isNull(formState.customer) && customerPermission) ||
      !!Object.values(formErrors).flat().length ||
      isLoading
    )
  }, [formState, isLoading, formErrors])

  const createSingleShipment = () => createShipment(false)

  const createShipmentAndNext = () => createShipment(true)

  const createShipment = async (isAddAnother: boolean) => {
    setIsLoading(true)

    try {
      const newShipment = await createVisibilityShipment({
        container_number: formState.container_number,
        shared_reference: formState.shared_reference,
        bl_number: formState.bl_number,
        booking_number: formState.booking_number,
        carrier_id: formState.carrier?.id,
        customer_role_id:
          (formState.customer?.preferred_shipment_role_id as number) ||
          (user.preferredShipmentRoleId as number),
        customer_organization_id: formState.customer?.id ?? user.organizationId,
      })
      dispatch({ type: actions.SET_VISIBILITY_SHIPMENT, payload: newShipment })

      const searchParameters = formatFiltersToParams(filters)

      await Promise.all([
        shipmentsGetData(searchParameters),
        shipmentsGetMetaData(searchParameters),
      ]).catch(() => {
        dispatch(
          showNotification({
            message: 'Shipments update failed due to an error',
            severity: 'error',
            showClose: true,
          })
        )
      })
      dispatch(
        showNotification({
          message: t('common.notifications.shipment_created'),
          severity: 'success',
          actionText: t('common.buttons.go_to_shipment'),
          actionHandler: () => history.push(`shipments/${newShipment?.id}`),
          showClose: true,
        })
      )
      onClose(isAddAnother)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)

      dispatch(
        showNotification({
          message: t('common.notifications.shipment_not_created'),
          severity: 'error',
          showClose: true,
        })
      )
    }
  }

  const onClose = (status) => {
    onChange(initialState)
    if (!status) {
      handleModalOpen(false)
    }
  }

  const onCloseWindow = () => onClose(false)

  const formProviderValue: VisibilityFormContextProps = {
    formState,
    onChange,
    onFormErrorChange,
    isLoading,
    formErrors,
  }

  return (
    <FormProvider value={formProviderValue}>
      <Dialog
        data-testid="shipment-visibility-modal"
        sx={{
          '.MuiDialog-paper': {
            width: '100%',
            minWidth: '50vw',
          },
          '.MuiDialog-paper .MuiDialogTitle-root': {
            paddingBottom: 1,
          },
          '.MuiDialogTitle-root': {
            fontWeight: 600,
          },
        }}
        open={open}
        onClose={onCloseWindow}
        title={t('add_shipment.title', 'Add Shipment')}
        actions={
          <>
            {!isLoading && (
              <LoadingButton
                variant="text"
                onClick={createShipmentAndNext}
                disabled={isDisabled}
                size="large"
              >
                {t(
                  'add_shipment.buttons.save_and_add_another',
                  'Save and add another'
                )}
              </LoadingButton>
            )}
            <LoadingButton
              data-testid="save-shipment-button"
              variant="contained"
              onClick={createSingleShipment}
              disabled={isDisabled}
              loading={isLoading}
              size="large"
            >
              {t('add_shipment.buttons.add_shipment', 'Add Shipment')}
            </LoadingButton>
          </>
        }
      >
        <DialogContent>
          <ShipmentInfoFields />
          <TrackingInformation />
        </DialogContent>
      </Dialog>
    </FormProvider>
  )
}
export default ShipmentVisibilityModal
