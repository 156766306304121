import { MRT_Cell, MRT_RowData } from 'material-react-table'
import { OverviewTaskTypes } from 'src/services/Api/overview/types'
import OverviewTableText from './OverviewTableText'
import OverviewTableTasksTask from './OverviewTableTasksTask'

interface OverviewTableTasksProps<TData extends MRT_RowData> {
  taskType: OverviewTaskTypes
  cell: MRT_Cell<TData>
}

type OverviewTableTasksTaskProps = {
  task_type_id: number
  task_status_id: number
  task_id: number
}

const OverviewTableTasks = ({
  cell,
  taskType,
}: OverviewTableTasksProps<MRT_RowData>) => {
  const value = cell?.getValue() as OverviewTableTasksTaskProps[]
  const tasks: OverviewTableTasksTaskProps[] = value ?? []
  const task = tasks.find(
    (task: OverviewTableTasksTaskProps) => task.task_type_id === taskType.id
  )
  const taskStatus = taskType?.statuses?.find((status) => {
    return status.id === task?.task_status_id
  })

  if (!taskStatus) {
    return <OverviewTableText text="-" />
  }

  return (
    <OverviewTableTasksTask
      task={task}
      taskType={taskType}
      taskStatus={taskStatus}
    />
  )
}

export default OverviewTableTasks
